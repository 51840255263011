import MdSelect from "@/components/Material/MdField/MdSelect/MdSelect.vue"
import MdOption from "@/components/Material/MdField/MdSelect/MdOption.vue"
import MdOptgroup from "@/components/Material/MdField/MdSelect/MdOptgroup.vue"

const MdSelectPlugin = {
  install(app) {
    app.component("md-select", MdSelect)
    app.component("md-option", MdOption)
    app.component("md-optgroup", MdOptgroup)
  },
}

export default MdSelectPlugin
