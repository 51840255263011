import PerfectScrollbar from "vue3-perfect-scrollbar"
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"

const ScrollbarPlugin = {
  install(app) {
    const options = {
      watchOptions: true,
      options: {
        suppressScrollX: true,
      },
    }

    app.use(PerfectScrollbar, options)
  },
}

export default ScrollbarPlugin
