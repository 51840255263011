<template>
  <div class="user">
    <div class="photo">
      <img :src="avatar" alt="avatar" />
    </div>
    <a class="user-menu" data-toggle="collapse" :aria-expanded="!isClosed">
      <span class="title">
        {{ username }}
      </span>
    </a>
    <collapse-transition>
      <div v-show="!isClosed">
        <ul class="nav">
          <slot> </slot>
        </ul>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import { computed } from "vue"
import { useAuthStore } from "@/store/auth"

export default {
  setup() {
    const auth = useAuthStore()

    const username = computed(() => {
      return auth.user?.full_name || "Admin"
    })

    return { username }
  },

  props: {
    avatar: {
      type: String,
      default: "./img/default-avatar.png",
    },
    isClosed: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.collapsed {
  transition: opacity 1s;
}
</style>
