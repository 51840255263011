<template>
  <VField
    as="div"
    class="md-field-validated"
    v-model="model"
    :name="name"
    :type="type"
    :rules="rules"
    v-slot="{ field, meta, errors }"
  >
    <md-field
      :id="'form-field-input-' + name"
      :class="[
        { 'md-error': (meta.dirty || isValidated) && !meta.valid },
        { 'md-valid': !!model && meta.valid },
        { 'md-form-group': formGroup },
      ]"
    >
      <md-icon v-if="icon !== ''">{{ icon }}</md-icon>
      <label :id="'form-input-' + name">{{ label }}</label>
      <md-input
        :bindProps="field"
        :id="'form-input-' + name"
        :name="name"
        :type="type"
        :value="modelValue"
        @update:model-value="
          value => $emit('update:modelValue', value ? (isUppercase ? value.toUpperCase() : value) : value)
        "
      />
      <slide-y-down-transition>
        <md-icon class="error" v-show="(meta.dirty || isValidated) && !meta.valid">close</md-icon>
      </slide-y-down-transition>
      <slide-y-down-transition>
        <md-icon class="success" v-show="!!model && meta.valid">done</md-icon>
      </slide-y-down-transition>
    </md-field>
    <slide-y-down-transition>
      <div
        :class="[{ 'error-messages': true }, { 'form-group-errors': formGroup }]"
        v-if="showError && (meta.dirty || isValidated) && !meta.valid"
      >
        <span v-for="(error, index) in errors" :key="index">
          {{ getProperErrorMessage(error) }}
        </span>
      </div>
    </slide-y-down-transition>
  </VField>
</template>

<script>
import { Field as VField } from "vee-validate"

export default {
  name: "form-input",

  components: {
    VField,
  },

  props: {
    name: String,
    type: String,
    label: String,
    rules: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    formGroup: {
      type: Boolean,
      default: true,
    },
    showError: {
      type: Boolean,
      default: true,
    },
    isValidated: {
      type: Boolean,
      default: null,
    },
    isUppercase: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      required: true,
    },
  },

  data() {
    return {
      model: null,
    }
  },

  methods: {
    getProperErrorMessage(string) {
      const parsed = string.replace(/([A-Z])/g, " $1")
      return parsed.charAt(0).toUpperCase() + parsed.slice(1)
    },
  },

  emits: ["update:modelValue"],
}
</script>
