<template>
  <div class="full-page auth-layout">
    <auth-header />
    <div class="wrapper wrapper-full-page">
      <div class="page-header header-filter" :class="setPageClass" filter-color="black" :style="setBgImage">
        <div class="container md-offset">
          <router-view v-slot="{ Component }">
            <zoom-center-transition :duration="300" mode="out-in">
              <component :is="Component" />
            </zoom-center-transition>
          </router-view>
        </div>
        <auth-footer />
      </div>
    </div>
  </div>
</template>

<script>
import AuthHeader from "./Layout/Header.vue"
import AuthFooter from "./Layout/Footer.vue"

export default {
  components: {
    AuthHeader,
    AuthFooter,
  },

  inject: {
    autoClose: {
      default: true,
    },
  },

  computed: {
    setBgImage() {
      let images = {
        Forgot: "./img/forgot.jpg",
        SetPassword: "./img/set-password.jpg",
        Login: "./img/login.jpg",
        Register: "./img/register.jpg",
        Lock: "./img/lock.jpg",
      }

      if (this.$route.name.includes("Register")) {
        return {
          backgroundImage: `url(${images["Register"]})`,
        }
      }

      if (this.$route.name.includes("Login")) {
        return {
          backgroundImage: `url(${images["Login"]})`,
        }
      }

      return {
        backgroundImage: `url(${images[this.$route.name]})`,
      }
    },
    setPageClass() {
      if (this.$route.name.includes("Register")) {
        return "register-page".toLowerCase()
      }
      return `${this.$route.name}-page`.toLowerCase()
    },
  },
}
</script>

<style lang="scss" scoped>
$scaleSize: 0.1;
$zoomOutStart: 0.7;
$zoomOutEnd: 0.46;

@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}
.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
    transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
  }
  to {
    opacity: 0;
    transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);
  }
}
.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
