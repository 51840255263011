<template>
  <Doughnut :height="height" :options="chartOptions" :data="chartData" />
</template>

<script>
import { Doughnut } from "vue-chartjs"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"

import tinycolor from "tinycolor2"

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

export default {
  name: "doughnut-chart",

  components: { Doughnut },

  props: {
    height: {
      type: Number,
      default: 264,
    },
    title: {
      type: String,
      default: "",
    },
    chartData: Object,
    // chartOptions: Object, -> for customization in the future
  },

  computed: {
    chartOptions() {
      return {
        plugins: {
          datalabels: {
            backgroundColor: function (ctx) {
              return ctx.dataset.backgroundColor.map(item => {
                const original = tinycolor(item)
                return original.darken(20).toRgbString()
              })
            },
            display: ctx => {
              const data = ctx.dataset.data
              const total = data.reduce((acc, curr) => {
                return acc + curr
              }, 0)

              const value = data[ctx.dataIndex]
              if (total <= 0) {
                return false
              }

              return value / total > 0.05
            },
            formatter: (value, ctx) => {
              const label = ctx.chart.data.labels[ctx.dataIndex]
              return value >= 5 ? label : ""
            },
            animation: {
              duration: 500,
            },

            font: { size: 8 },
            align: "end",
            anchor: "cemter",
            offset: -8,

            borderColor: "white",
            borderRadius: 2,
            borderWidth: 1,
            padding: 4,
            color: "white",
          },
          title: {
            display: true,
            text: this.title,
          },
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    },
  },
}
</script>
