import api from "@/api/axios"

export default {
  async downloadTemplate() {
    return await api.get("/v1/template", { responseType: "blob" })
  },

  async fetchRequests() {
    return await api.get("/v1/requests")
  },

  async fetchRequestFile(filename) {
    return await api.get(`/v1/file/${filename}`)
  },

  async upload(payload, signal) {
    return await api.post("/v1/uploads", payload, {
      headers: { "content-type": "multipart/form-data" },
      signal: signal,
    })
  },

  async download(payload) {
    return await api.get("/v1/downloads", {
      params: { ...payload },
      responseType: "blob",
    })
  },

  async sendPassword(payload) {
    return await api.post("/v1/file/password", payload)
  },
}
