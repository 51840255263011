import MdRipple from "../../../MdRipple/MdRipple.vue"

export default {
  components: {
    MdRipple,
  },
  props: {
    mdRipple: {
      type: Boolean,
      default: true,
    },
  },
}
