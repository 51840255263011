<template>
  <md-card class="file-output-container">
    <md-progress-bar :md-value="progress" md-mode="determinate" v-if="processing || !completed" />
    <md-card-content>
      <div class="file-output-content" :key="processing || loaded">
        <div class="summary-info">
          <div class="summary-icon"><md-icon>folder_copy</md-icon></div>
          <st-skeleton :is-loaded="loaded" skeleton-class="file-output-skeleton" radius="4px">
            <div class="summary">
              <div class="file-output-name-container">
                <div class="filename value">
                  {{ filename }}
                  <span v-if="email"> ({{ email }}) </span>
                </div>
              </div>
              <div class="file-output-value-container">
                <div class="requests">
                  <span class="label">Request Count:</span>
                  <span class="value" v-for="(requests, index) in requestArray" :key="index">
                    <animated-number :value="requests" v-if="![',', '%', '.'].includes(requests)"></animated-number>
                    <span v-else>{{ requests }}</span>
                  </span>
                </div>
                <span class="separator">,</span>
                <div class="hits">
                  <span class="label">Hit Count:</span>
                  <span class="value" v-for="(hits, index) in hitsArray" :key="index">
                    <animated-number :value="hits" v-if="![',', '%', '.'].includes(hits)"></animated-number>
                    <span v-else>{{ hits }}</span>
                    <span class="separator" v-if="hitsArray.length !== hitsArray.lastIndexOf(hits) + 1">,</span>
                  </span>
                </div>
                <span class="separator">,</span>
                <div class="hit_rate">
                  <span class="label">Hit Rate:</span>
                  <div class="hit_rate_items" v-for="(hitRateArray, itemName) in hitRates" :key="itemName">
                    <span class="hit_rate_name" :key="itemName">{{ itemName }}:</span>
                    <span class="value rate" v-for="(hitRate, index) in hitRateArray" :key="index">
                      <animated-number :value="hitRate" v-if="![',', '%', '.'].includes(hitRate)"></animated-number>
                      <span v-else>{{ hitRate }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </st-skeleton>
        </div>
        <md-button
          :key="completed"
          class="md-button md-primary md-round md-theme-default"
          @click="handleFileDownload(filename)"
          :disabled="!completed || loading"
        >
          <template v-if="!completed">Processing<st-wave /></template>
          <template v-else>
            <md-progress-spinner :md-diameter="20" :md-stroke="4" md-mode="indeterminate" v-if="loading" />
            <template v-else>download file</template>
          </template>
        </md-button>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { ref, computed, watchEffect } from "vue"
import { useUploadStore } from "@/store/upload"
// import usePassword from "@/hooks/usePassword"
import useProgress from "@/hooks/useProgress"

export default {
  name: "file-output",

  props: {
    filename: String,
    email: [String, null],
    requestArray: Array,
    hitsArray: Array,
    hitRates: [Array, Object],
    isLoaded: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const store = useUploadStore()
    const completed = ref(false)
    const processing = ref(false)
    const percentage = ref(0)
    const filepath = ref("")

    // const { generatePassword, passwordAlert } = usePassword()
    const { progress } = useProgress({
      percentage: percentage.value,
      isProcessing: processing.value,
      isCompleted: completed.value,
      isLoaded: props.isLoaded,
    })

    const loaded = computed(() => {
      return props.isLoaded
    })

    const loading = computed(() => {
      if (filepath.value == "") {
        return false
      }

      if (typeof store.summary[filepath.value] == "undefined") {
        return false
      }

      return store.summary[filepath.value].loading
    })

    const handleFileDownload = async filename => {
      store.download({ file_name: filename })
      filepath.value = filename
    }

    watchEffect(async () => {
      let interval = null
      if (props.filename && props.filename !== "") {
        interval = setInterval(async () => {
          if (!completed.value) {
            const filename = props.filename.replace("%20", " ").replace("_summary.", ".")
            const response = await store.fetchRequestFile(filename)

            percentage.value = response.percentage
            processing.value = response.processing
            completed.value = response.completed

            if (response.completed) {
              clearInterval(interval)
            }
          }
        }, 2500)
      }
    })

    // NOTE: This feature on hold for now!
    // const handleFileDownload = async filename => {
    //   const targetPassword = generatePassword()
    //   store.sendPassword({ code: targetPassword })

    //   const { value: password } = await passwordAlert.fire({
    //     title: "Password-Protected File",
    //     inputLabel: "Sending password to your mobile number. Please wait...",
    //     inputValidator: value => {
    //       return new Promise(resolve => {
    //         if (value === targetPassword) {
    //           resolve()
    //         } else {
    //           resolve("You have entered an incorrect password")
    //         }
    //       })
    //     },
    //   })

    //   if (password) {
    //     store.download({ file_name: filename })
    //     filepath.value = filename
    //   }
    // }

    return { store, progress, loaded, processing, completed, loading, handleFileDownload }
  },
}
</script>
