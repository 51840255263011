import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"

import Redirect from "./redirect"
import Toast from "./toast"

const SweetAlertPlugin = {
  install(app) {
    app.config.globalProperties.$redirect = Redirect
    app.config.globalProperties.$toast = Toast

    app.use(VueSweetalert2)
  },
}

export default SweetAlertPlugin
