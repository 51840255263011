import MdRipple from "@/components/Material/MdRipple/MdRipple"
import MdWave from "@/components/Material/MdRipple/MdWave"

const MdRipplePlugin = {
  install(app) {
    app.component(MdRipple.name, MdRipple)
    app.component(MdWave.name, MdWave)
  },
}

export default MdRipplePlugin
