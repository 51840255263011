import upload from "@/api/upload"
import { defineStore } from "pinia"
import moment from "moment"

import useAlert from "@/hooks/useAlert"
import useDownload from "@/hooks/useDownload"

const { loading, success, danger, close } = useAlert()
const { download } = useDownload()

export const useUploadStore = defineStore("upload", {
  state: () => {
    return {
      template: {
        loading: false,
        error: null,
      },
      requests: {
        data: null,
        loading: true,
        error: null,
      },
      file: {
        data: null,
        loading: true,
        error: null,
      },
      current: {
        data: null,
        loading: false,
        error: null,
      },
      summary: [
        {
          data: null,
          loading: false,
          error: null,
        },
      ],
      controller: null,
    }
  },

  actions: {
    updateRequestsData(data) {
      this.requests.data = data

      setTimeout(() => {
        this.requests.loading = false
      }, [500])
    },

    createController() {
      this.controller = new AbortController()
    },

    cancelUpload() {
      if (this.controller) {
        this.controller.abort()
        this.controller = null
      }
    },

    async downloadTemplate() {
      this.template.loading = true
      return await upload
        .downloadTemplate()
        .then(response => {
          this.template.loading = false

          const type = response.headers["Content-Type"]
          const datetime = moment().format("yyyy-MM-DD-HH-mm-ss")
          const filename = "Template_" + datetime + ".xlsx"
          download(response.data, type, filename)
        })
        .catch(error => {
          this.template.loading = false
          if (error.message) {
            return (this.template.error = error.message)
          }
          return (this.template.error = error.response?.data?.message)
        })
    },

    async fetchRequests() {
      this.requests.loading = true

      return await upload
        .fetchRequests()
        .then(response => {
          this.requests.data = response.data.data
          this.requests.loading = false

          return this.requests.data
        })
        .catch(error => {
          this.requests.data = []
          this.requests.loading = false
          let message = error.response?.data?.message || error.message

          return (this.requests.error = message)
        })
    },

    async fetchRequestFile(filename) {
      this.file.loading = true

      return await upload
        .fetchRequestFile(filename)
        .then(response => {
          this.file.data = response.data.data
          this.file.loading = false

          return this.file.data
        })
        .catch(error => {
          this.file.data = []
          this.file.loading = false
          let message = error.response?.data?.message || error.message

          return (this.file.error = message)
        })
    },

    async upload(payload) {
      this.current.loading = true
      this.createController()

      const signal = this.controller ? this.controller.signal : null

      const cancelUpload = () => {
        this.cancelUpload()
      }

      loading
        .fire({
          title: "Please wait while we are processing your request!",
          showCancelButton: true,
        })
        .then(result => {
          if (result.isDismissed) {
            cancelUpload()
          }
        })

      return await upload
        .upload(payload, signal)
        .then(response => {
          this.current.loading = false
          this.current.data = response.data.data
          this.requests.data = [response.data.data].concat(this.requests.data)

          close()
          return response.data.data
        })
        .catch(error => {
          this.current.loading = false

          if (error.name === "CanceledError") {
            return Promise.reject(error)
          }
          if (error.message) {
            this.current.error = error?.message
            return error.response
          }

          this.current.error = error?.response?.data?.message
          return error.response
        })
    },

    async download(payload) {
      this.summary[payload.file_name] = { data: null, loading: true, error: null }

      return await upload
        .download(payload)
        .then(response => {
          this.summary[payload.file_name].loading = false
          const type = response.headers["Content-Type"]
          download(response.data, type, payload.file_name)
        })
        .catch(error => {
          this.template.loading = false
          if (error.message) {
            return (this.template.error = error.message)
          }
          return (this.template.error = error.response?.data?.message)
        })
    },

    async sendPassword(payload) {
      return await upload
        .sendPassword(payload)
        .then(() => {
          return true
        })
        .catch(() => {
          danger.fire({
            text: "Unable to send password to your registered mobile number. Please try again later!",
          })
          return false
        })
    },
  },
})
