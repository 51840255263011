<template>
  <md-table class="md-table-timeline">
    <md-table-row class="md-table-head">
      <md-table-cell></md-table-cell>
      <md-table-cell class="position-end">Amount</md-table-cell>
      <md-table-cell class="position-end">Download</md-table-cell>
    </md-table-row>
    <md-table-row slot="md-table-row" v-for="(item, index) in invoices" :key="index">
      <md-table-cell>
        <div class="invoice-item-date-name">
          <span class="date">{{ item.date }}</span>
          <span class="name">{{ item.name }}</span>
        </div>
      </md-table-cell>
      <md-table-cell class="position-end">{{ item.sales }}</md-table-cell>
      <md-table-cell class="position-end">
        <md-button class="md-primary md-simple"><md-icon>download</md-icon></md-button>
      </md-table-cell>
    </md-table-row>
  </md-table>
</template>

<script>
export default {
  name: "invoice-table",

  props: {
    invoices: Array,
  },
}
</script>

<style lang="scss">
.invoice-item-date-name {
  display: flex;
  flex-direction: column;

  .date {
    font-weight: 500;
  }

  .name {
    padding-top: 2px !important;
    font-size: 0.8rem !important;
  }
}

.position-end {
  .md-table-cell-container {
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
  }
  .md-ripple {
    padding: 0px !important;
  }
}
</style>
