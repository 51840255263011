import MdSpeedDial from "./MdSpeedDial"
import MdSpeedDialTarget from "./MdSpeedDialTarget"
import MdSpeedDialContent from "./MdSpeedDialContent"

const MdSpeedDialPlugin = {
  install(app) {
    app.component(MdSpeedDial.name, MdSpeedDial)
    app.component(MdSpeedDialTarget.name, MdSpeedDialTarget)
    app.component(MdSpeedDialContent.name, MdSpeedDialContent)
  },
}

export default MdSpeedDialPlugin
