<template>
  <Transition
    :duration="duration"
    name="fade-transition"
    enter-active-class="fade-move"
    enter-from-class="fade-in"
    leave-to-class="fade-out"
    leave-active-class="fade-move"
  >
    <slot></slot>
  </Transition>
</template>

<script>
import { baseTransition } from "./mixin.js"

export default {
  name: "fade-transition",
  mixins: [baseTransition],
}
</script>

<style>
.fade-in,
.fade-out {
  opacity: 0;
}

.fade-move {
  transition: transform 0.3s ease-out;
}
</style>
