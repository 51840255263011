<template>
  <div v-bind="getRootProps()" class="drag-drop-container dropzone">
    <input v-bind="getInputProps()" id="file-input" class="hidden" />
    <div class="drag-drop-icon">
      <md-icon>file_copy</md-icon>
    </div>
    <div class="drag-drop-action-text">
      <span>Drag & Drop to Upload File</span>
      <div>OR</div>
      <md-button class="md-primary md-sm" @click="open">Browse File</md-button>
    </div>
    <small class="small-text">Supports CSV, XLS, XLSX</small>
  </div>
</template>

<script setup>
import { useDropzone } from "vue3-dropzone"

const emit = defineEmits(["drop:file"])

const onDrop = acceptFiles => {
  emit("drop:file", acceptFiles)
}

const options = { onDrop, noClick: true }
const { getRootProps, getInputProps, open } = useDropzone(options)
</script>

<style lang="scss">
.hidden {
  visibility: hidden;
}
.drag-drop-container {
  z-index: 4;
  width: 100%;
  color: #000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: move;
  cursor: grab;

  height: inherit !important;

  border-width: 2px;
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;

  small {
    text-align: center;
  }
}

.drag-drop-icon {
  position: relative;
  top: 8px;

  height: 72px !important;
  text-align: center;

  .md-icon {
    font-size: 4.5rem !important;
  }
}

.drag-drop-action-text {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 98px !important;

  span {
    font-size: 1.2rem !important;
  }
}

.dropzone.dz-drag-hover {
  background-color: #ccc;
}
</style>
