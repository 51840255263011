<template>
  <div class="pagination-tool">
    <md-card-actions md-alignment="space-between">
      <div class="pagination-details">
        <p class="card-category">{{ entries }}</p>
      </div>
      <paginator
        :key="index"
        class="pagination-no-border pagination-success"
        type="primary"
        :total="total"
        :per-page="perPage"
        :page-count="pageCount"
        :current-page="currentPage"
        @change:page="value => onChangePage(value)"
      >
      </paginator>
    </md-card-actions>
    <div class="per-page-selection">
      <md-field>
        <label for="perPage">Per page</label>
        <md-select
          name="perPage"
          :value="perPage"
          :md-close-on-select="true"
          @md-selected="value => onChangePerPage(value)"
        >
          <md-option
            v-for="(item, idx) in perPageOptions"
            :key="idx"
            :label="item"
            :value="item"
            :class="[{ 'md-selected': item == perPage }]"
          >
            {{ item }}
          </md-option>
        </md-select>
      </md-field>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue"
import { breakpointsBootstrapV5, useBreakpoints } from "@vueuse/core"
import { Paginator } from "@/components"

const props = defineProps({
  isLoaded: {
    type: Boolean,
    default: false,
  },
  total: Number,
  from: Number,
  to: Number,
  perPage: Number,
  pageCount: Number,
  currentPage: Number,
})

const emit = defineEmits(["change:page", "change:per-page"])
const breakpoints = useBreakpoints(breakpointsBootstrapV5)

const index = ref(1)
const perPageOptions = ref([15, 30, 45, 60])

const entries = computed(() => {
  if (breakpoints.smaller("sm").value) {
    return `${props.from + 1} - ${props.to} / ${props.total}`
  }

  return `Showing ${props.from + 1} to ${props.to} of ${props.total} entries`
})

const onChangePage = value => {
  index.value = value
  emit("change:page", value)
}

const onChangePerPage = value => {
  index.value = 1
  emit("change:per-page", value)
  emit("change:page", 1)
}
</script>
