<template>
  <VField
    as="div"
    class="md-field-validated"
    v-model="model"
    :name="name"
    :rules="rules"
    v-slot="{ field, errors, meta }"
  >
    <md-field
      :id="'form-field-country-' + name"
      :class="[
        { 'md-form-country': true },
        { 'md-error': (meta.dirty || isValidated) && !meta.valid },
        { 'md-valid': !!model && meta.valid },
        { 'md-form-group': formGroup },
      ]"
    >
      <md-icon v-if="icon !== ''">{{ icon }}</md-icon>
      <label :id="'form-country-' + name">{{ label }}</label>
      <md-select
        :bindProps="field"
        :id="'form-country-' + name"
        :name="name"
        v-model="model"
        :value="modelValue"
        md-class="country-menu"
        @update:model-value="value => $emit('update:modelValue', value)"
      >
        <md-option :value="item.value" v-for="(item, index) in options" :key="index">
          {{ item.label }}
          <div class="country-option">
            <flag :iso="item.value" />
          </div>
        </md-option>
      </md-select>
      <slide-y-down-transition>
        <md-icon class="error" v-show="(meta.dirty || isValidated) && !meta.valid">close</md-icon>
      </slide-y-down-transition>
      <slide-y-down-transition>
        <md-icon class="success" v-show="!!model && meta.valid">done</md-icon>
      </slide-y-down-transition>
    </md-field>
    <slide-y-down-transition>
      <div
        :class="[{ 'error-messages': true }, { 'form-group-errors': true }]"
        v-if="showError && (meta.dirty || isValidated) && !meta.valid"
      >
        <span v-for="(error, index) in errors" :key="index">
          {{ error.charAt(0).toUpperCase() + error.slice(1) }}
        </span>
      </div>
    </slide-y-down-transition>
  </VField>
</template>

<script>
import { Field as VField } from "vee-validate"

export default {
  name: "form-country",

  components: {
    VField,
  },

  props: {
    name: String,
    label: String,
    rules: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    formGroup: {
      type: Boolean,
      default: true,
    },
    showError: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      required: true,
    },
    isValidated: {
      type: Boolean,
      default: null,
    },
    options: Array,
  },

  data() {
    return {
      model: this.modelValue,
    }
  },

  emits: ["update:modelValue"],
}
</script>

<style lang="scss">
.country-option {
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    margin-left: 4px;
    font-size: 12px;
  }
}

.md-select-menu.country-menu {
  max-width: 360px !important;
}
</style>
