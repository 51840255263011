import FadeTransition from "@/components/Transitions/FadeTransition.vue"
import CollapseTransition from "@/components/Transitions/CollapseTransition.vue"
import ScaleTransition from "@/components/Transitions/ScaleTransition.vue"

import SlideXLeftTransition from "@/components/Transitions/Slide/SlideXLeftTransition.vue"
import SlideXRightTransition from "@/components/Transitions/Slide/SlideXRightTransition.vue"
import SlideYDownTransition from "@/components/Transitions/Slide/SlideYDownTransition.vue"
import SlideYUpTransition from "@/components/Transitions/Slide/SlideYUpTransition.vue"

import ZoomCenterTransition from "@/components/Transitions/Zoom/ZoomCenterTransition.vue"
import ZoomUpTransition from "@/components/Transitions/Zoom/ZoomUpTransition.vue"
import ZoomXTransition from "@/components/Transitions/Zoom/ZoomXTransition.vue"
import ZoomYTransition from "@/components/Transitions/Zoom/ZoomYTransition.vue"

const TransitionComponents = {
  install(Vue) {
    Vue.component("fade-transition", FadeTransition)
    Vue.component("collapse-transition", CollapseTransition)
    Vue.component("scale-transition", ScaleTransition)
    Vue.component("slide-x-left-transition", SlideXLeftTransition)
    Vue.component("slide-x-right-transition", SlideXRightTransition)
    Vue.component("slide-y-down-transition", SlideYDownTransition)
    Vue.component("slide-y-up-transition", SlideYUpTransition)
    Vue.component("zoom-center-transition", ZoomCenterTransition)
    Vue.component("zoom-up-transition", ZoomUpTransition)
    Vue.component("zoom-x-transition", ZoomXTransition)
    Vue.component("zoom-y-transition", ZoomYTransition)
  },
}

export default TransitionComponents
