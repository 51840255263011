import Swal from "sweetalert2"

let timerInterval

const Redirect = Swal.mixin({
  html: "Redirecting in <b></b> milliseconds.",
  timer: 3000,
  timerProgressBar: true,
  allowOutsideClick: false,
  showConfirmButton: false,
  showCancelButton: true,
  didOpen: () => {
    const b = Swal.getHtmlContainer().querySelector("b")
    timerInterval = setInterval(() => {
      b.textContent = Swal.getTimerLeft()
    }, 100)
  },
  willClose: () => {
    clearInterval(timerInterval)
  },
})

export default Redirect
