import stats from "@/api/stats"
import { defineStore } from "pinia"

export const useStatsStore = defineStore("stats", {
  state: () => {
    return {
      stats: {
        data: null,
        loading: false,
        error: null,
      },
      chart: {
        frequency: "Daily",
        range: 20,
        maxValue: 10,
        data: null,
        loading: false,
        error: null,
      },
    }
  },

  actions: {
    async statistics(refetch = false) {
      if (!refetch) {
        this.stats.loading = true
      }

      return await stats
        .statistics()
        .then(response => {
          this.stats.data = response.data.data
          this.stats.loading = false
          return response.data.data
        })
        .catch(error => {
          this.stats.data = []
          this.stats.loading = false
          let message = error.response?.data?.message || error.message

          return (this.stats.error = message)
        })
    },

    async chartData(params, refetch = false) {
      if (!refetch) {
        this.chart.loading = true
      }

      return await stats
        .chartData(params)
        .then(response => {
          const responseData = response.data.data
          this.chart.data = responseData.data
          this.chart.range = responseData.range
          this.chart.maxValue = responseData.maxValue
          this.chart.loading = false

          return responseData
        })
        .catch(error => {
          this.chart.data = []
          this.chart.loading = false
          let message = error.response?.data?.message || error.message

          return (this.chart.error = message)
        })
    },
  },
})
