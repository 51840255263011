var DNI_REGEX = /^(([KLM]\d{7})|(\d{8}))([A-Z])$/
var CIF_REGEX = /^([ABCDEFGHJNPQRSUVW])(\d{7})([0-9A-J])$/
var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/

const spainIdType = str => {
  if (str.match(DNI_REGEX)) {
    return "dni"
  }
  if (str.match(CIF_REGEX)) {
    return "cif"
  }
  if (str.match(NIE_REGEX)) {
    return "nie"
  }
  return ""
}

const validDNI = dni => {
  var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE"
  if (dni[0].match(/[KLM]/)) {
    dni = dni.substr(1)
  }
  var letter = dni_letters.charAt(parseInt(dni, 10) % 23)

  return letter == dni.charAt(dni.length - 1)
}

const validNIE = nie => {
  var nie_prefix = nie.charAt(0)

  switch (nie_prefix) {
    case "X":
      nie_prefix = 0
      break
    case "Y":
      nie_prefix = 1
      break
    case "Z":
      nie_prefix = 2
      break
  }

  return validDNI(nie_prefix + nie.substr(1))
}

const validCIF = cif => {
  var match = cif.match(CIF_REGEX)
  var letter = match[1],
    number = match[2],
    control = match[3]

  var even_sum = 0
  var odd_sum = 0
  var last_digit = 0
  var n

  for (var i = 0; i < number.length; i++) {
    n = parseInt(number.charAt(i), 10)

    if (i % 2 === 0) {
      n *= 2
      odd_sum += n < 10 ? n : n - 9
    } else {
      even_sum += n
    }
  }

  last_digit = parseInt((even_sum + odd_sum).toString().slice(-1), 10)

  var control_digit = last_digit != 0 ? 10 - last_digit : last_digit
  var control_letter = "JABCDEFGHI".substr(control_digit, 1)

  if (letter.match(/[ABEH]/)) {
    return control == control_digit
  }

  if (letter.match(/[PQSW]/)) {
    return control == control_letter
  }

  return control == control_digit || control == control_letter
}

const validateSpanish = str => {
  if (!str) return
  str = str.toUpperCase().replace(/\s/, "")

  var valid = false
  var type = spainIdType(str)

  switch (type) {
    case "dni":
      valid = validDNI(str)
      break
    case "nie":
      valid = validNIE(str)
      break
    case "cif":
      valid = validCIF(str)
      break
  }

  return {
    idType: type + " ",
    valid: valid,
  }
}

export default validateSpanish
