import Swal from "sweetalert2"

const useAlert = () => {
  const loading = Swal.mixin({
    icon: "info",
    showConfirmButton: false,
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading()
    },
  })

  const success = Swal.mixin({
    title: "Success",
    icon: "success",
    didOpen: () => {
      Swal.hideLoading()
      Swal.enableButtons()
    },
  })

  const danger = Swal.mixin({
    title: "Error!",
    icon: "error",
    showConfirmButton: true,
    didOpen: () => {
      Swal.hideLoading()
      Swal.enableButtons()
    },
  })

  const alert = Swal.mixin({
    title: "Alert!",
    icon: "warning",
    allowOutsideClick: false,
    showConfirmButton: true,
    didOpen: () => {
      Swal.hideLoading()
      Swal.enableButtons()
    },
  })

  const confirm = Swal.mixin({
    title: "Confirmation",
    showCancelButton: true,
    confirmButtonText: "Confirm",
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
  })

  const toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const request = Swal.mixin({
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    showCancelButton: true,
    confirmButtonText: "Submit",
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
  })

  const showLoading = () => {
    Swal.showLoading()
  }

  const hideLoading = () => {
    Swal.hideLoading()
  }

  const close = () => {
    Swal.close()
  }

  return {
    loading,
    success,
    danger,
    confirm,
    alert,
    toast,
    request,
    showLoading,
    hideLoading,
    close,
  }
}

export default useAlert
