<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ routeName }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="[{ toggled: showSidebar }]"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>
        <div class="md-collapse" :class="[{ 'off-canvas-sidebar': responsive }]">
          <admin-sidebar :is-closed="!showSidebar" :on-hide-sidebar="onHideSidebar"></admin-sidebar>
        </div>
        <div class="md-collapse user-dropdown-menu">
          <md-list>
            <drop-down class="user">
              <md-button slot="title" class="md-button md-primary md-round dropdown-toggle" data-toggle="dropdown">
                {{ username }}&nbsp;
              </md-button>
              <ul class="dropdown-menu">
                <li><a href="javascript:void(0)" @click.prevent="onLockAccount">Lock Account</a></li>
                <li><a href="javascript:void(0)" @click.prevent="onLogout">Logout</a></li>
              </ul>
            </drop-down>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import { ref, computed } from "vue"
import { useAuthStore } from "@/store/auth"
import AdminSidebar from "./Sidebar.vue"

export default {
  name: "admin-header",

  components: {
    AdminSidebar,
  },

  setup() {
    const auth = useAuthStore()
    const responsive = ref(false)
    const showMenu = ref(false)
    const showSidebar = ref(false)
    const menuTransitionDuration = ref(250)

    const username = computed(() => {
      return auth.user?.full_name || "Admin"
    })

    const onLogout = () => {
      auth.logout().then(() => {
        auth.$reset()
      })
    }

    const onLockAccount = () => {
      auth.lockAccount()
    }

    return {
      username,
      responsive,
      showMenu,
      showSidebar,
      menuTransitionDuration,
      onLogout,
      onLockAccount,
    }
  },

  computed: {
    routeName() {
      if (this.$route.name.includes(":")) {
        const names = this.$route.name.split(":")
        return `${names[1]}`
      }

      return this.$route.name
    },
  },

  methods: {
    toggleSidebar() {
      const sidebarState = !this.$sidebar.showSidebar
      this.$sidebar.displaySidebar(sidebarState)
      this.showSidebar = sidebarState

      this.$emit("show:sidebar", sidebarState)
    },
    onHideSidebar() {
      this.$sidebar.displaySidebar(false)
      this.showSidebar = false

      this.$emit("show:sidebar", false)
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open")
      this.showMenu = !this.showMenu
    },
    closeMenu() {
      document.body.classList.remove("nav-open")
      this.showMenu = false
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    },
  },

  mounted() {
    this.onResponsiveInverted()
    window.addEventListener("resize", this.onResponsiveInverted)
  },

  beforeDestroy() {
    this.closeMenu()
    window.removeEventListener("resize", this.onResponsiveInverted)
  },

  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu()
      setTimeout(() => {
        next()
      }, this.menuTransitionDuration)
    } else {
      next()
    }
  },

  emits: ["show:sidebar"],
}
</script>
