import AnimatedNumber from "@/components/AnimatedNumber.vue"
import Badge from "@/components/Badge.vue"
import Collapse from "@/components/Collapse.vue"
import DragDrop from "@/components/DragDrop.vue"
import DropDown from "@/components/Dropdown.vue"
import Empty from "@/components/Empty.vue"
import FileOutput from "@/components/FileOutput.vue"
import InfinteScroll from "@/components/InfiniteScroll.vue"
import Skeleton from "@/components/Skeleton.vue"
import Tabs from "@/components/Tabs.vue"
import Wave from "@/components/Wave.vue"

const GlobalComponents = {
  install(Vue) {
    Vue.component("st-badge", Badge)
    Vue.component("st-collapse", Collapse)
    Vue.component("st-empty", Empty)
    Vue.component("st-skeleton", Skeleton)
    Vue.component("st-tabs", Tabs)
    Vue.component("st-wave", Wave)
    Vue.component("drag-drop", DragDrop)
    Vue.component("drop-down", DropDown)
    Vue.component("file-output", FileOutput)
    Vue.component("infinite-scroll", InfinteScroll)
    Vue.component("animated-number", AnimatedNumber)
  },
}

export default GlobalComponents
