import MdField from "./MdField"
import MdFile from "./MdFile/MdFile"
import MdInput from "./MdInput/MdInput"
import MdTextarea from "./MdTextarea/MdTextarea"

const MdFieldPlugin = {
  install(app) {
    app.component(MdField.name, MdField)
    app.component(MdFile.name, MdFile)
    app.component(MdInput.name, MdInput)
    app.component(MdTextarea.name, MdTextarea)
  },
}

export default MdFieldPlugin
