<template>
  <md-card class="md-card-timeline" :class="{ 'md-card-plain': plain }">
    <ul class="timeline" :class="{ 'timeline-simple': type === 'simple' }">
      <slot></slot>
    </ul>
  </md-card>
</template>
<script>
export default {
  name: "timeline",
  props: {
    plain: Boolean,
    type: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="scss">
.timeline-heading {
  margin-right: 12px !important;

  @media (max-width: 575px) {
    margin-top: 6px !important;
  }
}
</style>
