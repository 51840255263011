<template>
  <div class="empty-content-container">
    <div class="empty-icon">
      <md-icon>no_accounts</md-icon>
    </div>
    <div class="empty-text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "st-empty",

  props: {
    text: {
      type: String,
      default: "No Data",
    },
  },
}
</script>

<style lang="scss" scoped>
.empty-content-container {
  width: 100% !important;
  height: 100% !important;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    height: 360px !important;
    min-height: auto !important;
  }

  .empty-icon {
    height: 72px !important;

    i {
      font-size: 86px !important;
      color: #3c4858 !important;

      @media (max-width: 767px) {
        font-size: 70px !important;
      }
    }
  }

  .empty-text {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: #3c4858 !important;
    text-align: center !important;
  }
}
</style>
