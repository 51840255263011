<template>
  <md-toolbar md-elevation="0" class="md-transparent md-toolbar-absolute">
    <div class="md-toolbar-row md-offset">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ routeName }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <div class="md-collapse">
          <md-list>
            <md-list-item>
              <md-icon>dashboard</md-icon>
              <a class="header" href="https://www.skiptraze.com/">SkipTraze</a>
            </md-list-item>
            <drop-down class="register-header" v-if="$route.name.includes('Login') && !$route.name.includes('Admin')">
              <template #title>
                <md-list-item href="javascript:void(0)">
                  <md-icon>person_add</md-icon>
                  Register
                </md-list-item>
              </template>
              <ul class="dropdown-menu">
                <li><a href="#/dca/register">DCA</a></li>
                <li class="invalid">
                  <a href="#/internal/register" @click="e => showAlert(e, 'Internal')">Internal</a>
                </li>
                <li class="invalid"><a href="#/vendor/register" @click="e => showAlert(e, 'Vendor')">Vendor</a></li>
              </ul>
            </drop-down>
            <md-list-item v-if="!['Login', 'Lock'].includes($route.name)" @click.prevent="onRedirectLogin">
              <md-icon>fingerprint</md-icon>
              Login
            </md-list-item>
            <md-list-item v-if="$route.name == 'Lock'" @click.prevent="onLogout">
              <md-icon>logout</md-icon>
              Logout
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import { useAuthStore } from "@/store/auth"
import router from "@/router"

export default {
  name: "auth-header",

  computed: {
    routeName() {
      if (this.$route.name.includes("Lock")) {
        return "Account Locked"
      }

      if (this.$route.name.includes("Forgot")) {
        return "Reset Password"
      }

      if (this.$route.name.includes("SetPassword")) {
        return "Set Password"
      }

      if (this.$route.name.includes("Register")) {
        const names = this.$route.name.split(":")
        return `${names[0]} (${names[1]})`
      }

      return this.$route.name
    },
  },

  setup() {
    const auth = useAuthStore()

    const onLogout = () => {
      auth.logout().then(() => {
        auth.$reset()
      })
    }

    const onRedirectLogin = () => {
      router.push({ path: "/login" })
    }

    return { onLogout, onRedirectLogin }
  },

  methods: {
    showAlert(event, type) {
      event.preventDefault()
      event.stopPropagation()

      this.$swal.fire({
        title: `${type} view is not yet available`,
        buttonsStyling: false,
        confirmButtonClass: "md-button md-primary",
      })
    },
  },
}
</script>

<style lang="scss">
.auth-layout {
  li.invalid {
    cursor: not-allowed !important;

    a {
      pointer-events: none;
    }
  }

  .register-header.dropdown {
    .md-list-item-content {
      padding: 4px 16px !important;
    }
  }

  .md-toolbar.md-transparent .md-list .md-list-item-content {
    color: #fff !important;
  }

  .md-toolbar-row {
    color: #fff !important;

    .md-title {
      font-size: 0.92rem !important;
      font-weight: 500 !important;
    }

    i {
      color: #fff !important;
      margin-right: 16px !important;
    }
  }
}
</style>
