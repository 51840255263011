import api, { cancelAllRequests, refreshToken } from "@/api/axios"

export default {
  async login(payload) {
    await api.get("/sanctum/csrf-cookie")
    return api.post("/login", payload)
  },

  async register(payload) {
    await api.get("/sanctum/csrf-cookie")
    return api.post("/register", payload)
  },

  async logout() {
    cancelAllRequests()
    refreshToken()
    return api.post("/logout")
  },

  async broadcast(payload) {
    await api.get("/sanctum/csrf-cookie")
    return api.post("/broadcasting/auth", {
      socket_id: payload.socketId,
      channel_name: channel.name,
    })
  },

  async unlock(payload) {
    return api.post("/v1/unlock", payload)
  },

  async forgotPassword(payload) {
    return api.post("/forgot-password", payload)
  },

  async resetPassword(id, params) {
    return api.get(`/reset-password/${id}/verify`, {
      params: params,
    })
  },

  async setPassword(payload) {
    return api.post("/set-password", payload)
  },

  async checkCompany(companyId) {
    return api.get(`/check/${companyId}`)
  },
}
