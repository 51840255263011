<template>
  <md-card class="md-card-drag-drop">
    <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
      <slot name="header"></slot>
    </md-card-header>

    <div class="drag-drop-content">
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <slot name="module"></slot>
          </div>
        </div>
      </md-card-content>
    </div>
  </md-card>
</template>

<script>
export default {
  name: "drag-drop-card",
  props: {
    headerColor: {
      type: String,
      default: "",
    },
  },

  methods: {
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + ""
    },
  },
}
</script>
