var NIP_REGEX = /^[0-9]{10}$/u
var REGON_REGEX = /^[0-9]{9,14}$/u

const polandIdType = str => {
  if (str.includes("-") && str.replace(/-/gu, "").match(NIP_REGEX)) {
    return "nip"
  }
  if (str.match(NIP_REGEX)) {
    return "nip"
  }
  if (str.match(REGON_REGEX)) {
    return "regon"
  }
  return ""
}

const checksum = (number, weights) => {
  const max = number.length - 1
  let sum = 0

  for (let i = 0; i < max; ++i) {
    const n = parseInt(number.charAt(i), 10)
    sum += n * weights[i]
  }

  const control = sum % 11
  const resultSum = control === 10 ? 0 : control
  const lastDigit = parseInt(number.slice(-1), 10)

  return resultSum === lastDigit
}

const validNIP = nip => {
  if (typeof nip !== "string") {
    return false
  }

  let nipString = nip
  if (nip.includes("-")) {
    nipString = nip.replace(/-/gu, ``)
  }

  const dig = String(nipString).split("")

  const controlValues = [6, 5, 7, 2, 3, 4, 5, 6, 7]
  const partialSums = controlValues.map((controlValue, index) => {
    return controlValue * parseInt(dig[index], 10)
  })

  let sum = 0
  partialSums.forEach(partialSum => {
    sum += partialSum
  })

  const control = sum % 11

  if (parseInt(dig[9], 10) === control) {
    return true
  }

  return false
}

const validREGON = regon => {
  const weights9 = [8, 9, 2, 3, 4, 5, 6, 7]

  if (regon.length === 9) {
    return checksum(regon, weights9)
  }

  const weights14 = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8]
  return checksum(regon.slice(0, 9), weights9) && checksum(regon, weights14)
}

const validatePolish = str => {
  if (!str) return
  str = str.toUpperCase().replace(/\s/, "")

  var valid = false
  var type = polandIdType(str)

  switch (type) {
    case "nip":
      valid = validNIP(str)
      break
    case "regon":
      valid = validREGON(str)
      break
  }

  return {
    idType: type + " ",
    valid: valid,
  }
}

export default validatePolish
