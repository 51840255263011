<template>
  <Transition
    name="zoom-x-transition"
    :duration="duration"
    enter-active-class="zoom-move"
    enter-from-class="zoomInX"
    leave-to-class="zoomOutX"
    leave-active-class="zoom-move"
  >
    <slot></slot>
  </Transition>
</template>

<script>
import { baseTransition } from "../mixin.js"

export default {
  name: "zoom-x-transition",
  props: {
    styles: {
      type: Object,
      default: () => {
        return {
          animationFillMode: "both",
          animationTimingFunction: "cubic-bezier(.55,0,.1,1)",
        }
      },
    },
  },
  mixins: [baseTransition],
}
</script>

<style lang="scss">
.zoomInX,
.zoomOutX {
  opacity: 0;
  transform: scaleX(0);
}

.zoom-move {
  transition: transform 0.3s ease-out;
}
</style>
