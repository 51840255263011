<template>
  <Transition
    name="zoom-y-transition"
    :duration="duration"
    enter-active-class="zoom-move"
    enter-from-class="zoomInY"
    leave-to-class="zoomOutY"
    leave-active-class="zoom-move"
  >
    <slot></slot>
  </Transition>
</template>

<script>
import { baseTransition } from "../mixin.js"

export default {
  name: "zoom-y-transition",
  mixins: [baseTransition],
  props: {
    styles: {
      type: Object,
      default: () => {
        return {
          animationFillMode: "both",
          animationTimingFunction: "cubic-bezier(.55,0,.1,1)",
        }
      },
    },
  },
}
</script>

<style lang="scss">
.zoomInY,
.zoomOutY {
  opacity: 0;
  transform: scaleY(0);
}

.zoom-move {
  transition: transform 0.3s ease-out;
}
</style>
