import MdChips from "./MdChips"
import MdChip from "./MdChip"

const MdChipsPlugin = {
  install(app) {
    app.component(MdChips.name, MdChips)
    app.component(MdChip.name, MdChip)
  },
}

export default MdChipsPlugin
