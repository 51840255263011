<template>
  <md-card class="md-card-timeline">
    <md-card-header
      class="md-card-header-icon"
      :class="[{ [getClass(headerColor)]: true }, { 'md-card-plain': plain }]"
    >
      <slot name="header"></slot>
    </md-card-header>

    <md-card-content>
      <ul class="timeline-content">
        <slot name="content"></slot>
      </ul>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "timeline-card",

  props: {
    headerColor: {
      type: String,
      default: "",
    },
    plain: Boolean,
  },

  methods: {
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + ""
    },
  },
}
</script>
