import Notifications from "./Notifications.vue"

const NotificationStore = {
  state: [], // here the notifications will be added

  removeNotification(timestamp) {
    const indexToDelete = this.state.findIndex(n => n.timestamp === timestamp)
    if (indexToDelete !== -1) {
      this.state.splice(indexToDelete, 1)
    }
  },
  addNotification(notification) {
    notification.timestamp = new Date()
    notification.timestamp.setMilliseconds(notification.timestamp.getMilliseconds() + this.state.length)
    this.state.push(notification)
  },
  notify(notification) {
    if (Array.isArray(notification)) {
      notification.forEach(notificationInstance => {
        this.addNotification(notificationInstance)
      })
    } else {
      this.addNotification(notification)
    }
  },
}

const NotificationsPlugin = {
  install(app) {
    app.config.globalProperties.$notify = NotificationStore.notify
    app.config.globalProperties.$notifications = NotificationStore

    app.component("notifications", Notifications)
  },
}

export default NotificationsPlugin
