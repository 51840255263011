const phone = () => {
  const fullPhone = value => {
    if (value.charAt(0) !== "+") {
      return false
    }

    if (value.includes("-")) {
      return value.match(/^\+(\d{1})-(\d{3})[0-9]{7}$/)
    }

    return value.match(/^\+(\d{2,3})[0-9]{9}$/)
  }

  const splitPhone = (value, target) => {
    var full = `${target}${value}`

    if (full.charAt(0) !== "+") {
      return false
    }

    if (target.includes("-")) {
      return full.match(/^\+(\d{1})-(\d{3})[0-9]{7}$/)
    }

    if (target.length == 4) {
      return full.match(/^\+(\d{3})[0-9]{9}$/)
    }

    return full.match(/^\+(\d{2})[0-9]{9}$/)
  }

  return { fullPhone, splitPhone }
}

export default phone
