<template>
  <div class="md-group" :class="className">
    <template :key="index" v-for="(item, index) in items">
      <md-button
        :id="'md-button-' + index"
        :class="[{ [getButtonColor]: true }, { [getButtonSize]: true }, { active: selected == item }]"
        @click="onSelectItem(item)"
      >
        {{ item }}
      </md-button>
    </template>
  </div>
</template>

<script>
export default {
  name: "segmented-button",

  props: {
    items: Array,
    className: {
      type: String,
      default: "",
    },
    buttonColor: {
      type: String,
      default: "primary",
    },
    buttonSize: [String, null],
    value: String,
  },

  data() {
    return {
      selected: this.value,
    }
  },

  computed: {
    getButtonColor() {
      return `md-${this.buttonColor}`
    },

    getButtonSize() {
      if (!this.buttonSize) {
        return ""
      }

      return `md-${this.buttonSize}`
    },
  },

  methods: {
    onSelectItem(item) {
      this.selected = item
      this.$emit("select:item", item)
    },
  },

  emits: ["select:item"],
}
</script>
