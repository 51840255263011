<template>
  <dca-header :companyName="companyName" :userName="userName"></dca-header>
  <div class="wrapper-container">
    <div class="main-panel">
      <fixed-plugin />
      <div :class="{ content: !$route.meta.hideContent }">
        <router-view v-slot="{ Component }">
          <zoom-center-transition>
            <component :is="Component" />
          </zoom-center-transition>
        </router-view>
      </div>
      <dca-footer v-if="!$route.meta.hideFooter"></dca-footer>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useAuthStore } from "@/store/auth"
import DcaHeader from "./Layout/Header.vue"
import DcaFooter from "./Layout/Footer.vue"
import FixedPlugin from "@/pages/FixedPlugin.vue"

export default {
  name: "DcaLayout",

  components: {
    DcaHeader,
    DcaFooter,
    FixedPlugin,
  },

  setup() {
    const store = useAuthStore()

    const companyName = computed(() => {
      return store.company?.name || ""
    })

    const userName = computed(() => {
      return store.user?.full_name || ""
    })

    return { companyName, userName }
  },
}
</script>

<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
