<template>
  <component :is="tag" class="badge" :class="`badge-${type}`">
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: "st-badge",
  props: {
    tag: {
      type: String,
      default: "span",
    },
    type: {
      type: String,
      default: "default",
      validator: value => {
        let acceptedValues = ["primary", "info", "success", "warning", "danger", "rose"]
        return acceptedValues.indexOf(value) !== -1
      },
    },
  },
}
</script>
<style></style>
