import MdCard from "./MdCard"
import MdCardArea from "./MdCardArea/MdCardArea"
import MdCardHeader from "./MdCardHeader/MdCardHeader"
import MdCardHeaderText from "./MdCardHeader/MdCardHeaderText"
import MdCardMedia from "./MdCardMedia/MdCardMedia"
import MdCardMediaActions from "./MdCardMedia/MdCardMediaActions"
import MdCardMediaCover from "./MdCardMedia/MdCardMediaCover"
import MdCardContent from "./MdCardContent/MdCardContent"
import MdCardExpand from "./MdCardExpand/MdCardExpand"
import MdCardExpandTrigger from "./MdCardExpand/MdCardExpandTrigger"
import MdCardExpandContent from "./MdCardExpand/MdCardExpandContent"
import MdCardActions from "./MdCardActions/MdCardActions"

const MdCardPlugin = {
  install(app) {
    app.component(MdCard.name, MdCard)
    app.component(MdCardArea.name, MdCardArea)
    app.component(MdCardHeader.name, MdCardHeader)
    app.component(MdCardHeaderText.name, MdCardHeaderText)
    app.component(MdCardMedia.name, MdCardMedia)
    app.component(MdCardMediaActions.name, MdCardMediaActions)
    app.component(MdCardMediaCover.name, MdCardMediaCover)
    app.component(MdCardContent.name, MdCardContent)
    app.component(MdCardExpand.name, MdCardExpand)
    app.component(MdCardExpandTrigger.name, MdCardExpandTrigger)
    app.component(MdCardExpandContent.name, MdCardExpandContent)
    app.component(MdCardActions.name, MdCardActions)
  },
}

export default MdCardPlugin
