import material from "@/components/Material/material"
import MdAvatar from "./MdAvatar"

const MdAvatarPlugin = {
  install(app) {
    app.component(MdAvatar.name, MdAvatar)
  },
}

export default MdAvatarPlugin
