<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="https://skiptraze.com/about-us" target="_blank">About Us</a>
          </li>
          <li>
            <a href="https://www.skiptraze.com/documents" target="_blank">Documents</a>
          </li>
        </ul>
      </nav>
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        by Skiptraze
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "admin-footer",
}
</script>

<style lang="scss">
.footer {
  padding: 0px !important;
}
</style>
