<template>
  <Transition
    name="slide-x-left-transition"
    :duration="duration"
    enter-active-class="slide-move"
    enter-from-class="slideXLeftIn"
    leave-to-class="slideXLeftOut"
    leave-active-class="slide-move"
  >
    <slot></slot>
  </Transition>
</template>

<script>
import { baseTransition } from "../mixin.js"

export default {
  name: "slide-x-left-transition",
  mixins: [baseTransition],
  props: {
    styles: {
      type: Object,
      default: () => {
        return {
          animationFillMode: "both",
          animationTimingFunction: "cubic-bezier(.25,.8,.50,1)",
        }
      },
    },
  },
}
</script>

<style lang="scss">
.slideXLeftIn,
.slideXLeftOut {
  opacity: 0;
  transform: translateX(-15px);
}

.slide-move {
  transition: transform 0.3s;
}
</style>
