<template>
  <admin-sidebar :is-closed="!showSidebar" :on-hide-sidebar="onHideSidebar"></admin-sidebar>
  <div class="admin-panel full-page" :class="[{ 'nav-open': showSidebar }]">
    <admin-header @show:sidebar="value => (showSidebar = value)"></admin-header>
    <div class="wrapper-full-page" :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
      <router-view v-slot="{ Component }">
        <zoom-center-transition :duration="200" mode="out-in">
          <component :is="Component" />
        </zoom-center-transition>
      </router-view>
    </div>
    <admin-footer v-if="!$route.meta.hideFooter"></admin-footer>
  </div>
</template>

<script>
import AdminHeader from "./Layout/Header.vue"
import AdminFooter from "./Layout/Footer.vue"
import AdminSidebar from "./Layout/Sidebar.vue"

export default {
  components: {
    AdminSidebar,
    AdminHeader,
    AdminFooter,
  },

  data() {
    return {
      sidebarMini: true,
      sidebarImg: true,
      showSidebar: false,
    }
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
        this.showSidebar = false
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
    onHideSidebar(event) {
      event.preventDefault()
    },
  },

  watch: {
    sidebarMini() {
      this.minimizeSidebar()
    },
  },
}
</script>

<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
