import GlobalComponents from "@/plugins/components"
import VueMaterial from "@/plugins/material"
import TransitionComponents from "@/plugins/transition"

import NotificationsPlugin from "./NotificationPlugin"
import SidebarPlugin from "./SidebarPlugin"
import SweetAlertPlugin from "./SweetAlertPlugin"

import ChartPlugin from "./chart"
import ScrollbarPlugin from "./scrollbar"
import PiniaPlugin from "./pinia"

import VueClickAway from "vue3-click-away"
import FlagIcon from "vue-flag-icon"

import "@/assets/css/demo.css"
import "@/assets/scss/dashboard.scss"

import "@/scripts/rules"
import "es6-promise/auto"

export default {
  install(Vue) {
    Vue.use(GlobalComponents)
    Vue.use(VueMaterial)
    Vue.use(TransitionComponents)
    Vue.use(VueClickAway)
    Vue.use(NotificationsPlugin)
    Vue.use(SidebarPlugin)
    Vue.use(SweetAlertPlugin)
    Vue.use(ChartPlugin)
    Vue.use(ScrollbarPlugin)
    Vue.use(PiniaPlugin)
    Vue.use(FlagIcon)
  },
}
