<template>
  <div class="user-mobile-menu">
    <sidebar-item :link="{ name: 'Logout', icon: 'logout', path: '/' }" @click.prevent="onLogout" />
  </div>
</template>

<script>
import { useAuthStore } from "@/store/auth"

export default {
  setup() {
    const auth = useAuthStore()

    const onLogout = () => {
      auth.logout().then(() => {
        auth.$reset()
      })
    }

    return { onLogout }
  },
}
</script>
