<template>
  <router-view :key="authUser?.id"></router-view>
</template>

<script>
import { computed, watchEffect } from "vue"
import { useAuthStore } from "@/store/auth"
import { useUploadStore } from "@/store/upload"
import { useStatsStore } from "@/store/stats"
import useBroadcast from "@/hooks/useBroadcast"
import { debounce } from "throttle-debounce"

window.Pusher = require("pusher-js")

export default {
  setup() {
    const auth = useAuthStore()
    const stats = useStatsStore()
    const upload = useUploadStore()
    const { createInstance } = useBroadcast()

    const authToken = computed(() => {
      return auth.token
    })

    const authUser = computed(() => {
      return auth.user
    })

    const updateData = debounce(
      500,
      notification => {
        if (!upload.current.loading) {
          upload.updateRequestsData(notification.data)
        }

        stats.chartData({ frequency: stats.chart.frequency }, true)
      },
      { atBegin: true },
    )

    // watchEffect(() => {
    //   if (authUser.value && authToken.value) {
    //     let echo = createInstance(authToken.value)

    //     echo.private("App.Models.User." + authUser.value.id).notification(notification => {
    //       if (notification.type == "broadcast.dca.request" && notification.data) {
    //         updateData(notification)
    //       }
    //     })
    //   }
    // })

    return { authUser, authToken }
  },
}
</script>
