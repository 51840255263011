<template>
  <VForm v-slot="{ meta }">
    <md-field
      id="form-field-input-phone"
      :class="[
        { 'md-error': ((meta.dirty && meta.touched) || isValidated) && !meta.valid },
        { 'md-valid': !!modelValue && meta.valid },
        { 'md-form-group': formGroup },
        { 'md-phone-form': true },
        { 'md-has-value': prefix || prefix == '' },
      ]"
    >
      <md-icon>contact_phone</md-icon>
      <label for="form-input-phone">Phone</label>
      <VField v-model="prefix" :name="prefixName" rules="required" v-slot="{ field }">
        <md-select
          :bindProps="field"
          :name="prefixName"
          :class="[{ 'md-phone-form-group': true }, { 'md-focused': focused }]"
          id="form-select-phone"
          md-class="phone-prefix-menu"
          @md-opened="focused = true"
          @md-closed="focused = false"
          @md-selected="value => onChangePrefix(value)"
        >
          <md-option :value="item.value" v-for="(item, index) in options" :key="index">
            {{ item.label }} {{ item.value !== "" ? `(${item.value})` : "Please select" }}
          </md-option>
        </md-select>
      </VField>

      <VField
        v-model="phone"
        :name="name"
        :validate-on-input="true"
        rules="required|splitPhone:@prefix"
        v-slot="{ field, meta: { valid, touched, dirty } }"
      >
        <md-input
          :bindProps="field"
          id="form-input-phone"
          :name="name"
          type="text"
          :disabled="prefix == ''"
          :value="phone"
          :prevent-space="true"
          @update:model-value="value => onChangeInput(value)"
        />
        <slide-y-down-transition>
          <md-icon class="error" v-show="((meta.dirty && meta.touched) || (dirty && touched) || isValidated) && !valid">
            close
          </md-icon>
        </slide-y-down-transition>
        <slide-y-down-transition>
          <md-icon class="success" v-show="valid">done</md-icon>
        </slide-y-down-transition>
      </VField>
    </md-field>
  </VForm>
</template>

<script>
import { Form as VForm, Field as VField } from "vee-validate"
import countries from "@/scripts/countries.js"

export default {
  name: "form-phone",

  components: {
    VField,
    VForm,
  },

  props: {
    name: String,
    prefixName: {
      type: String,
      default: "prefix",
    },
    formGroup: {
      type: Boolean,
      default: true,
    },
    isValidated: {
      type: Boolean,
      default: null,
    },
    modelValue: {
      required: true,
    },
  },

  data() {
    return {
      prefix: null,
      phone: null,
      options: countries,
      focused: false,
    }
  },

  methods: {
    onChangePrefix(value) {
      this.prefix = value

      if (this.phone && this.prefix) {
        const phone = `${this.prefix}${this.phone}`
        this.$emit("update:modelValue", phone)
      }
    },

    onChangeInput(value) {
      this.phone = typeof value == "string" ? value.replace(/\s+/g, "") : value

      if (this.prefix && this.phone) {
        const phone = `${this.prefix}${this.phone}`
        this.$emit("update:modelValue", phone)
      }
    },
  },

  emits: ["update:modelValue"],
}
</script>

<style lang="scss">
.md-phone-form {
  input#form-input-phone {
    padding-left: 5px !important;
  }

  .input-hidden {
    visibility: hidden !important;
  }

  .md-menu.md-select {
    display: flex !important;
    flex: 1 1 0 !important;
    max-width: 140px !important;
    padding-left: 14px !important;
  }
}

.md-select-menu.phone-prefix-menu {
  max-width: 140px !important;
}
</style>
