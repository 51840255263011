import axios from "axios"
import router from "@/router"
import { useAuthStore } from "@/store/auth"
import useAlert from "@/hooks/useAlert"

let baseURL = process.env.VUE_APP_API_BASE_URL

if (!baseURL.startsWith("http://") && !baseURL.startsWith("https://")) {
  baseURL = window.location.origin + baseURL
}

const api = axios.create({
  baseURL: baseURL,
  withCredentials: true,
})

const cancelToken = api.CancelToken
let cancelSource = cancelToken?.source()

export const refreshToken = () => {
  cancelSource = cancelToken && cancelToken.source()
}

export const cancelAllRequests = () => {
  cancelSource && cancelSource.cancel()
}

/*
 * Add a response interceptor
 */
api.interceptors.response.use(
  response => response,
  function (error) {
    const { alert, toast } = useAlert()
    const auth = useAuthStore()

    if (!error.response) {
      return Promise.reject(error)
    }

    if (error.response.status === 419) {
      if (router.currentRoute.value.name.includes("Admin")) {
        return router.push("/admin/login")
      }

      return router.push("/login")
    }

    if (error.response.status === 401) {
      if (router.currentRoute.value.name.includes("Login")) {
        auth.$store
        return Promise.reject(error)
      }

      auth.logout().then(() => {
        auth.$store
      })

      delete window.axios.defaults.headers.common.Authorization
      localStorage.removeItem("auth")

      if (router.currentRoute.value.name.includes("Admin")) {
        return router.push("/admin/login")
      }

      return router.push("/login")
    }

    if ([400, 403].includes(error.response?.status)) {
      return Promise.reject(error)
    }

    if ([422, 500].includes(error.response?.status)) {
      toast.fire({
        title: "SERVER ERROR!",
        text: error.response?.data?.message,
      })
      return Promise.reject(error)
    }

    if ([409].includes(error.response?.status)) {
      if (!auth.isLoggingOut) {
        auth.logout().then(() => {
          auth.$reset()
        })
      }

      if (router.currentRoute.value.matched?.every(record => record.path.includes("login"))) {
        setTimeout(() => {
          location.reload()
        }, [2000])
      }

      return Promise.reject(error)
    }

    // if ([419].includes(error.response?.status)) {
    //   alert
    //     .fire({
    //       text: "Your session has expired!",
    //     })
    //     .then(result => {
    //       if (result.isConfirmed) {
    //         if (auth.loggedIn) {
    //           auth.logout().then(() => {
    //             auth.$reset()
    //           })
    //         } else {
    //           auth.$reset()
    //           router.push({ path: "/login" })
    //         }

    //         setTimeout(() => {
    //           location.reload()
    //         }, [2000])
    //       }
    //     })

    //   return Promise.reject(error)
    // }

    return Promise.reject(error)
  },
)

export default api
