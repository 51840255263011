import { ref, watchEffect, onMounted } from "vue"

const useProgress = ({ percentage, isProcessing, isCompleted, isLoaded }) => {
  const increment = ref(0.3)
  const progress = ref(percentage)

  const setLimits = () => {
    if (!isCompleted && isLoaded) {
      if (progress.value < 5) {
        progress.value = 5
      }

      if (progress.value > 95) {
        progress.value = 95
      }
    }
  }

  watchEffect(() => {
    let interval = null
    increment.value = (100 - percentage) / 1000

    if (!isCompleted && isLoaded) {
      interval = setInterval(() => {
        if (isLoaded && isProcessing) {
          progress.value = progress.value + increment.value
        }

        if (isCompleted || progress.value >= 100) {
          progress.value = 0
          clearInterval(interval)
        }
      }, 1000)
    }

    onMounted(() => {
      setLimits()
    })
  })

  return { progress, increment }
}

export default useProgress
