<template>
  <div class="md-layout-item md-size-100 md-small-size-100">
    <VField
      v-model="password"
      name="password"
      type="password"
      rules="required|alpha_num|number|uppercase|lowercase|min:8"
      v-slot="{ field, meta, errors }"
    >
      <md-field
        id="form-field-input-password"
        :class="[
          { 'md-error': (meta.dirty || isValidated) && !meta.valid },
          { 'md-valid': !!password && meta.valid },
          { 'md-form-group': true },
        ]"
      >
        <md-icon>lock_outline</md-icon>
        <label for="form-input-password">Password</label>
        <md-input
          :bindProps="field"
          id="form-input-password"
          name="password"
          :value="modelValue"
          type="password"
          ref="password"
          @update:model-value="value => $emit('update:modelValue', value)"
        >
        </md-input>
        <slide-y-down-transition>
          <md-icon class="error" v-show="(meta.dirty || isValidated) && !meta.valid">close</md-icon>
        </slide-y-down-transition>
        <slide-y-down-transition>
          <md-icon class="success" v-show="!!password && meta.valid">done</md-icon>
        </slide-y-down-transition>
      </md-field>
      <slide-y-down-transition>
        <div :class="[{ 'error-messages': true }, { 'form-group-errors': true }]">
          <span v-for="(error, index) in errors" :key="index">
            {{ getProperErrorMessage(error) }}
          </span>
        </div>
      </slide-y-down-transition>
    </VField>

    <VField
      v-model="confirmation"
      name="confirmation"
      type="password"
      rules="required|confirmed:@password"
      v-slot="{ field, meta }"
    >
      <md-field
        id="form-field-input-confirm-password"
        :class="[
          { 'md-error': (meta.dirty || isValidated) && !meta.valid },
          { 'md-valid': !!confirmation && meta.valid },
          { 'md-form-group': true },
        ]"
      >
        <md-icon>lock_reset</md-icon>
        <label for="form-input-confirm-password">Confirm Password</label>
        <md-input
          :bindProps="field"
          id="form-input-confirm-password"
          name="confirmation"
          :value="confirmation"
          type="password"
          @update:model-value="value => (confirmation = value)"
        >
        </md-input>
        <slide-y-down-transition>
          <md-icon class="error" v-show="(meta.dirty || isValidated) && !meta.valid">close</md-icon>
        </slide-y-down-transition>
        <slide-y-down-transition>
          <md-icon class="success" v-show="!!confirmation && meta.valid">done</md-icon>
        </slide-y-down-transition>
      </md-field>
      <slide-y-down-transition>
        <div
          :class="[{ 'error-messages': true }, { 'form-group-errors': true }]"
          v-if="(meta.dirty || isValidated) && !meta.valid"
        >
          <span v-if="!confirmation || confirmation == ''">Value for confirmation field is required</span>
          <span v-else>Password does not match</span>
        </div>
      </slide-y-down-transition>
    </VField>
  </div>
</template>

<script>
import { Field as VField } from "vee-validate"

export default {
  name: "form-password",

  components: {
    VField,
  },

  props: {
    isValidated: {
      type: Boolean,
      default: null,
    },
    modelValue: {
      required: true,
    },
  },

  data() {
    return {
      password: null,
      confirmation: null,
    }
  },

  methods: {
    getProperErrorMessage(string) {
      const parsed = string.replace(/([A-Z])/g, " $1")
      return parsed.charAt(0).toUpperCase() + parsed.slice(1)
    },
  },

  emits: ["update:modelValue"],
}
</script>
