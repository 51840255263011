import { createRouter, createWebHashHistory } from "vue-router"
import { useAuthStore } from "@/store/auth"
import routes from "@/router/routes"

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const store = useAuthStore()

  if (to.matched.some(record => record.meta.authOnly)) {
    // if user is not logged in
    if (!store.loggedIn || !store.user) {
      next({ path: "/login" })
      return
    }
  }

  if (to.matched.some(record => record.meta.dcaOnly)) {
    // if user is not logged in
    if (store.loggedIn && store.loggedInAs && store.user) {
      // if user is locked
      if (store.locked) {
        next({ path: "/lock" })
        return
      }

      if (store.loggedInAs.toLowerCase() !== "dca") {
        next({ path: "/login" })
        return
      }
    }
  }

  // guest route
  if (to.matched.some(record => record.meta.guestOnly)) {
    // if user is authenticated
    if (store.loggedIn && store.loggedInAs && store.user) {
      store.$reset()

      // if (store.loggedInAs.toLowerCase() == "dca") {
      //   // next({ path: "/dca/dashboard" })
      //   next({ path: "/login" })
      //   return
      // }

      // if (store.loggedInAs.toLowerCase() == "admin") {
      //   // next({ path: "/admin/dashboard" })
      //   next({ path: "/admin/login" })
      //   return
      // }
    }
  }

  next()
  return
})

export default router
