import "./base/index.scss"
import MdReactive from "./core/utils/MdReactive"

const material = new MdReactive({
  ripple: true,
  theming: {},
  locale: {
    startYear: 1900,
    endYear: 2099,
    dateFormat: "yyyy-MM-dd",
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    shorterDays: ["S", "M", "T", "W", "T", "F", "S"],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
    shorterMonths: ["J", "F", "M", "A", "M", "Ju", "Ju", "A", "Se", "O", "N", "D"],
    firstDayOfAWeek: 0,
    cancel: "Cancel",
    confirm: "Ok",
  },
  router: {
    linkActiveClass: "router-link-active",
  },
})

export default material
