import Echo from "laravel-echo"

const useBroadcast = () => {
  const createInstance = token => {
    let authEndpoint = process.env.VUE_APP_WEBSOCKET_URL

    if (!authEndpoint.startsWith("http://") && !authEndpoint.startsWith("https://")) {
      authEndpoint = window.location.origin + authEndpoint
    }

    let options = {
      broadcaster: "pusher",
      // cluster: process.env.VUE_APP_WEBSOCKET_CLUSTER,
      // key: process.env.VUE_APP_WEBSOCKET_KEY,
      // wsHost: process.env.VUE_APP_WEBSOCKET_WS_HOST,
      // wsPath: process.env.VUE_APP_WEBSOCKET_WS_PATH,
      // wsPort: process.env.VUE_APP_WEBSOCKET_WS_PORT,

      // use ably server
      key: process.env.VUE_APP_ABLY_KEY,
      wsHost: "realtime-pusher.ably.io",
      wsPort: 443,

      encrypted: false,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      forceTLS: false,
      authEndpoint: `${authEndpoint}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      },
    }

    if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging") {
      options["wssPort"] = process.env.VUE_APP_WEBSOCKET_WSS_PORT
      options["encrypted"] = true
      options["forceTLS"] = true
    }

    return new Echo(options)
  }

  return { createInstance }
}

export default useBroadcast
