<template>
  <ul class="pagination" :class="paginationClass">
    <li class="page-item prev-page" :key="index" :class="[{ disabled: current === 1, 'no-arrows': noArrows }]">
      <a class="page-link" aria-label="Previous" @click="prevPage" :disabled="current === 1">
        <i class="fas fa-angle-double-left"></i>
      </a>
    </li>
    <li
      class="page-item"
      :key="idx"
      v-for="(item, idx) in range(minPage, maxPage)"
      :class="[{ active: current === item }]"
    >
      <a class="page-link" @click="changePage(item)" :disabled="current === item">{{ item }}</a>
    </li>
    <li class="page-item next-page" :key="index" :class="[{ disabled: current === totalPages, 'no-arrows': noArrows }]">
      <a class="page-link" aria-label="Next" @click="nextPage" :disabled="current === totalPages">
        <i class="fas fa-angle-double-right"></i>
      </a>
    </li>
  </ul>
</template>

<script setup>
import { ref, computed } from "vue"

const props = defineProps({
  type: {
    type: String,
    default: "primary",
    validator: value => {
      return ["default", "primary", "danger", "success", "warning", "info", "rose"].includes(value)
    },
  },
  noArrows: {
    type: Boolean,
    default: false,
  },
  total: {
    type: Number,
    default: 0,
  },
  pageCount: {
    type: Number,
    default: 0,
  },
  perPage: {
    type: Number,
    default: 15,
  },
  currentPage: {
    type: Number,
    default: 1,
  },
})

const emit = defineEmits(["change:page"])

const index = ref(1)
const current = ref(props.currentPage)
const defaultPagesToDisplay = ref(5)

const paginationClass = computed(() => {
  return `pagination-${props.type}`
})

const totalPages = computed(() => {
  if (props.pageCount > 0) return props.pageCount
  if (props.total > 0) {
    return Math.ceil(props.total / props.perPage)
  }
  return 1
})

const pagesToDisplay = computed(() => {
  if (totalPages.value > 0 && totalPages.value < defaultPagesToDisplay.value) {
    return totalPages.value
  }
  return defaultPagesToDisplay.value
})

const minPage = computed(() => {
  if (current.value < pagesToDisplay.value) {
    return 1
  }

  const pagesToAdd = Math.floor(pagesToDisplay.value / 2)
  const newMaxPage = pagesToAdd + current.value

  if (newMaxPage > totalPages.value) {
    return totalPages.value - pagesToDisplay.value + 1
  }

  return current.value - pagesToAdd
})

const maxPage = computed(() => {
  if (current.value < pagesToDisplay.value) {
    return pagesToDisplay.value
  }

  const pagesToAdd = Math.floor(pagesToDisplay.value / 2)
  const newMaxPage = pagesToAdd + current.value

  if (newMaxPage < totalPages.value) {
    return newMaxPage
  }

  return totalPages.value
})

const range = (min, max) => {
  let arr = []
  for (let i = min; i <= max; i++) {
    arr.push(i)
  }
  return arr
}

const changePage = item => {
  current.value = item
  index.value = index.value + 1
  emit("change:page", item)
}

const nextPage = event => {
  event.preventDefault()
  if (current.value == totalPages.value) {
    event.stopPropagation()
    return
  }

  index.value = index.value + 1
  current.value = current.value + 1

  if (current.value <= totalPages.value) {
    emit("change:page", current.value)
  }
}

const prevPage = event => {
  event.preventDefault()
  if (current.value == 1) {
    event.stopPropagation()
    return
  }

  index.value = index.value + 1
  current.value = current.value - 1

  if (current.value >= 1) {
    emit("change:page", current.value)
  }
}
</script>
