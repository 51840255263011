import MdProgressBar from "@/components/Material/MdProgress/MdProgressBar"
import MdProgressSpinner from "@/components/Material/MdProgress/MdProgressSpinner"

const MdProgressPlugin = {
  install(app) {
    app.component(MdProgressBar.name, MdProgressBar)
    app.component(MdProgressSpinner.name, MdProgressSpinner)
  },
}

export default MdProgressPlugin
