<template>
  <div class="main-menu">
    <sidebar-item
      :link="{ name: 'Dashboard', icon: 'leaderboard', path: '/admin/dashboard' }"
      @click="e => $emit('change:route', e)"
    />
    <!-- <sidebar-item
      :link="{ name: 'Companies', icon: 'meeting_room', path: '/admin/companies' }"
      @click="e => $emit('change:route', e)"
    /> -->
    <sidebar-item
      :link="{ name: 'DCA', icon: 'data_exploration', path: '/admin/dca' }"
      @click="e => $emit('change:route', e)"
    />
    <!-- <sidebar-item
      :link="{ name: 'Internal', icon: 'sync_lock', path: '/admin/internal' }"
      @click="e => $emit('change:route', e)"
    />
    <sidebar-item
      :link="{ name: 'Vendor', icon: 'corporate_fare', path: '/admin/vendor' }"
      @click="e => $emit('change:route', e)"
    /> -->
  </div>
</template>

<script>
export default {
  name: "main-menu",
  emits: ["change:route"],
}
</script>
<style lang="scss" scoped></style>
