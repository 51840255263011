<template>
  <div class="md-toolbar-row">
    <div class="page-header dashboard header-filter clear-filter dashboard-header">
      <div class="img-skiptraze-bg-left" style="background-image: url(&quot;./img/bg-1.png&quot;)"></div>
      <div class="img-skiptraze-bg-right" style="background-image: url(&quot;./img/bg-sample.png&quot;)"></div>
      <div class="content-header">
        <div class="nav-header">
          <div class="breadcrumb">
            <md-button slot="buttons" href="https://www.skiptraze.com" target="__blank" class="md-just-icon md-simple">
              <md-icon>home</md-icon>
            </md-button>
            <span class="separator">/</span>
            <span class="current-page">{{ title }}</span>
          </div>
          <drop-down class="user">
            <md-button slot="title" class="md-button md-primary md-round dropdown-toggle md-sm" data-toggle="dropdown">
              {{ userName }}
            </md-button>
            <ul class="dropdown-menu">
              <li><a href="javascript:void(0)" @click.prevent="onLockAccount">Lock Account</a></li>
              <li><a href="javascript:void(0)" @click.prevent="onLogout">Logout</a></li>
            </ul>
          </drop-down>
        </div>
        <div class="title-header">
          <div class="title-header-content">
            <div class="title">{{ companyName }}</div>
            <div class="contacts">
              {{ userName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/store/auth"

export default {
  name: "dca-header",

  props: {
    companyName: String,
    userName: String,
  },

  setup() {
    const auth = useAuthStore()

    const onLogout = () => {
      auth.logout().then(() => {
        auth.$reset()
      })
    }

    const onLockAccount = () => {
      auth.lockAccount()
    }

    return { onLogout, onLockAccount }
  },

  data() {
    return {
      title: "Debt Collection Agency (DCA)",
      brand: "Skiptraze",
    }
  },
}
</script>

<style lang="scss" scoped>
.current-page {
  @media (max-width: 575px) {
    font-size: 14px;
  }
}

.md-toolbar-row {
  .dashboard-header {
    overflow: hidden;
    background-color: rgb(0, 68, 255, 0.8);

    .img-skiptraze-bg-left {
      width: 593px;
      height: 780px;
      position: absolute;
      z-index: 2;
      top: -610px;
      bottom: auto;
      left: -100px;
      right: auto;
      background-repeat: no-repeat;
      background-size: contain;
      @media (max-width: 767px) {
        width: 380px;
        top: -350px;
      }

      @media (max-width: 400px) {
        width: 320px;
        top: -312px;
      }
    }
    .img-skiptraze-bg-right {
      width: 593px;
      height: 780px;
      position: absolute;
      z-index: 2;
      top: 30px;
      bottom: auto;
      left: auto;
      right: -100px;
      background-repeat: no-repeat;
      background-size: contain;
      @media (max-width: 767px) {
        width: 380px;
        top: 50px;
      }

      @media (max-width: 400px) {
        width: 300px;
        top: 65px;
      }
    }
  }
}
</style>
