import MdSteppers from "./MdSteppers"
import MdStep from "./MdStep"

const MdSteppersPlugin = {
  install(app) {
    app.component(MdSteppers.name, MdSteppers)
    app.component(MdStep.name, MdStep)
  },
}

export default MdSteppersPlugin
