<template>
  <VField v-model="input" :name="name" :rules="rules" :value="input" type="checkbox" v-slot="{ field, meta }">
    <md-field
      :class="[
        { 'md-error': (meta.dirty || isValidated) && !checker },
        { 'md-valid': meta.dirty && checker },
        { 'md-form-group': formGroup },
        { 'md-checkbox-form': true },
        { 'md-checkbox-only': checkboxOnly },
      ]"
    >
      <md-checkbox
        :bindProps="field"
        :id="'form-checkbox-' + name"
        :name="name"
        type="checkbox"
        v-model="checker"
        :with-label="!checkboxOnly"
        @update:model-value="value => onUpdate(value)"
      >
        <slot name="label" v-if="!checkboxOnly"></slot>
      </md-checkbox>
      <slot name="extra"></slot>
      <slide-y-down-transition>
        <md-icon class="error" v-show="(meta.dirty || isValidated) && !checker">close</md-icon>
      </slide-y-down-transition>
      <slide-y-down-transition>
        <md-icon class="success" v-show="meta.dirty && checker">done</md-icon>
      </slide-y-down-transition>
    </md-field>
  </VField>
</template>

<script>
import { Field as VField } from "vee-validate"

export default {
  name: "form-checkbox",

  components: {
    VField,
  },

  props: {
    name: String,
    rules: {
      type: String,
      default: "",
    },
    formGroup: {
      type: Boolean,
      default: true,
    },
    isValidated: {
      type: Boolean,
      default: null,
    },
    modelValue: {
      required: true,
    },
    checkboxOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      checker: false,
      input: "false",
    }
  },

  methods: {
    onUpdate(value) {
      this.checker = value
      this.input = String(value)
      this.$emit("update:modelValue", value)
    },
  },

  emits: ["update:modelValue"],
}
</script>
