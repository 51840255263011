<template>
  <Transition
    name="zoom-center-transition"
    :duration="duration"
    enter-active-class="zoom-move"
    enter-from-class="zoomIn"
    leave-to-class="zoomOut"
    leave-active-class="zoom-move"
    :mode="mode"
  >
    <slot></slot>
  </Transition>
</template>

<script>
import { baseTransition } from "../mixin.js"

export default {
  name: "zoom-center-transition",
  mixins: [baseTransition],
}
</script>

<style lang="scss">
.zoomIn,
.zoomOut {
  opacity: 0;
  transform: scale3d(0.3, 0.3, 0.3);
}

.zoom-move {
  transition: transform 0.3s ease-out;
}
</style>
