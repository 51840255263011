<template>
  <side-bar
    :active-color="sidebarBackground"
    :background-image="sidebarBackgroundImage"
    :data-background-color="sidebarBackgroundColor"
  >
    <user-menu :is-closed="isClosed"></user-menu>
    <template #links>
      <main-menu @change:route="onHideSidebar"></main-menu>
      <extra-menu @change:route="onHideSidebar"></extra-menu>
      <user-mobile-menu></user-mobile-menu>
    </template>
  </side-bar>
</template>

<script>
import UserMenu from "./Menu/UserMenu.vue"
import MainMenu from "./Menu/MainMenu.vue"
import ExtraMenu from "./Menu/ExtraMenu.vue"
import UserMobileMenu from "./Menu/UserMobileMenu.vue"

export default {
  name: "admin-sidebar",

  components: {
    UserMenu,
    MainMenu,
    ExtraMenu,
    UserMobileMenu,
  },

  props: {
    isClosed: {
      type: Boolean,
      default: false,
    },
    onHideSidebar: {
      required: true,
    },
  },

  data() {
    return {
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage: "./img/sidebar.jpg",
    }
  },
}
</script>
