<template>
  <div id="wave">
    <span class="dot"></span>
    <span class="dot"></span>
    <span class="dot"></span>
  </div>
</template>

<script>
export default {
  name: "st-wave",
}
</script>

<style lang="scss" scoped>
div#wave {
  position: relative;
  text-align: center;
  margin-right: auto;
  margin-left: 12px;
}

div#wave .dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 5px;
  background: #eeeeee;
  animation: wave 1.3s linear infinite;
  opacity: 0.8;
}
div#wave .dot:nth-child(2) {
  animation-delay: -1.1s;
}
div#wave .dot:nth-child(3) {
  animation-delay: -0.9s;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-5px);
  }
}
</style>
