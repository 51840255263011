<template>
  <VField v-model="model" :name="name" :rules="rules" v-slot="{ field, errors, meta }">
    <md-field
      :id="'form-field-input-' + name"
      :class="[{ 'md-error': !meta.valid }, { 'md-valid': meta.valid && meta.dirty }, { 'md-form-group': formGroup }]"
    >
      <md-icon v-if="icon !== ''">{{ icon }}</md-icon>
      <label :id="'form-select-' + name">{{ label }}</label>
      <md-select
        :bindProps="field"
        :id="'form-select-' + name"
        :name="name"
        v-model="model"
        :value="modelValue"
        @update:model-value="value => $emit('update:modelValue', value)"
      >
        <md-option :value="item.value" v-for="(item, index) in options" :key="index">
          {{ item.label }}
        </md-option>
      </md-select>
      <slide-y-down-transition>
        <md-icon class="error" v-show="!meta.valid">close</md-icon>
      </slide-y-down-transition>
      <slide-y-down-transition>
        <md-icon class="success" v-show="meta.valid && meta.dirty">done</md-icon>
      </slide-y-down-transition>
    </md-field>
    <slide-y-down-transition>
      <div class="error-messages" v-if="showError && !meta.valid && errors">
        <span v-for="(error, index) in errors" :key="index">
          {{ error.charAt(0).toUpperCase() + error.slice(1) }}
        </span>
      </div>
    </slide-y-down-transition>
  </VField>
</template>

<script>
import { Field as VField } from "vee-validate"

export default {
  name: "form-select",

  components: {
    VField,
  },

  props: {
    name: String,
    label: String,
    rules: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    formGroup: {
      type: Boolean,
      default: true,
    },
    showError: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      required: true,
    },
    options: Array,
  },

  data() {
    return {
      model: null,
    }
  },

  emits: ["update:modelValue"],
}
</script>
