import MdList from "./MdList"
import MdListItem from "./MdListItem/MdListItem.vue"

const MdListPlugin = {
  install(app) {
    app.component(MdList.name, MdList)
    app.component(MdListItem.name, MdListItem)
  },
}

export default MdListPlugin
