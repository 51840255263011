<template>
  <div ref="container" :class="className">
    <slot></slot>
    <div ref="end">
      <span v-if="!isLastPage">
        <!-- loader here -->
      </span>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue"

defineProps({
  isLastPage: {
    type: Boolean,
    default: false,
  },
  className: String,
})

const emit = defineEmits(["infinite:scroll"])
const container = ref(null)
const end = ref(null)

onMounted(() => {
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          emit("infinite:scroll")
        }
      })
    },
    { root: container.value },
  )

  observer.observe(end.value)
})
</script>
