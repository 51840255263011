import { createApp } from "vue"
import App from "./App.vue"

import Plugins from "./plugins"
import router from "./router"

const app = createApp(App)

app.use(router)
app.use(Plugins)

app.mount("#app")
