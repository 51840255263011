import { cloneDeep } from "lodash"
import { createPinia } from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"

const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)

pinia.use(({ store }) => {
  const initialState = cloneDeep(store.$state)
  store.$reset = () => store.$patch(cloneDeep(initialState))
})

const PiniaPlugin = {
  install(app) {
    app.use(pinia)
  },
}

export default PiniaPlugin
