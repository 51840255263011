import MdTable from "./MdTableContainer"
import MdTableToolbar from "./MdTableToolbar"
import MdTableEmptyState from "./MdTableEmptyState"
import MdTableRow from "./MdTableRow"
import MdTableHead from "./MdTableHead"
import MdTableCell from "./MdTableCell"
import MdTablePagination from "./MdTablePagination"

const MdTablePlugin = {
  install(app) {
    app.component("MdTable", MdTable)
    app.component(MdTableToolbar.name, MdTableToolbar)
    app.component(MdTableEmptyState.name, MdTableEmptyState)
    app.component(MdTableRow.name, MdTableRow)
    app.component(MdTableHead.name, MdTableHead)
    app.component(MdTableCell.name, MdTableCell)
    app.component(MdTablePagination.name, MdTablePagination)
  },
}

export default MdTablePlugin
