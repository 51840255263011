export default {
  mdRounded: Boolean,
  mdSize: {
    type: Number,
    default: 420,
  },
  mdIcon: String,
  mdLabel: String,
  mdDescription: String,
}
