import personal from "./validators/personal"
import phone from "./validators/phone"

import { defineRule } from "vee-validate"
import { is, required, email, confirmed } from "@vee-validate/rules"

const { fullPhone, splitPhone } = phone()
const { validatePolish, validateSpanish } = personal()

defineRule("is", is)
defineRule("required", required)
defineRule("email", email)
defineRule("confirmed", confirmed)

defineRule("alpha", value => {
  if (value.match(/^[\w\-\s]+$/)) {
    return true
  }

  if (value.match(/^[\s\p{L}]+$/u)) {
    return true
  }

  return "Must contain only letters"
})

defineRule("alpha_num", value => {
  if (value.match(/^[A-Za-z0-9]/)) {
    return true
  }

  return "Must start with an alphanumeric character"
})

defineRule("min", (value, [length]) => {
  if (value.length >= length) {
    return true
  }

  return `Must have at least ${length} characters`
})

defineRule("max", (value, [length]) => {
  if (value.length < length) {
    return true
  }

  return `Must not exceeds ${length} characters`
})

defineRule("minmax", (value, [min, max]) => {
  if (value.length >= min && value.length <= max) {
    return true
  }

  return `Must be between ${min} and ${max}`
})

defineRule("uppercase", value => {
  if (value.match(/[A-Z]/g)) {
    return true
  }

  return "Must contain at least one uppercase character"
})

defineRule("lowercase", value => {
  if (value.match(/[a-z]/g)) {
    return true
  }

  return "Must contain at least one lowercase character"
})

defineRule("number", value => {
  if (value.match(/[0-9]/g)) {
    return true
  }

  return "Must contain at least one number"
})

defineRule("fullPhone", value => {
  if (fullPhone(value)) {
    return true
  }

  return "Must be a valid phone number"
})

defineRule("splitPhone", (value, [target]) => {
  if (splitPhone(value, target)) {
    return true
  }

  return "Must be a valid phone number"
})

defineRule("personal", (value, [phone, country]) => {
  if (phone == "48" || country == "pl") {
    const { idType, valid } = validatePolish(value)

    if (valid) {
      return true
    }

    return `Must be a valid ${idType} format`
  }

  if (phone == "34" || country == "es") {
    const { idType, valid } = validateSpanish(value)

    if (valid) {
      return true
    }

    return `Must be a valid ${idType} format`
  }

  return true
})
