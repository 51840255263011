<template>
  <div class="fixed-plugin" v-click-away="closeDropDown">
    <div class="dropdown show-dropdown" :class="[{ show: isOpen }]">
      <a data-toggle="dropdown">
        <i class="fa fa-cog fa-2x" @click="toggleDropDown"> </i>
      </a>
      <ul class="dropdown-menu" :class="[{ show: isOpen }]">
        <li class="header-title">Company Information</li>
        <md-table class="md-table-plugin">
          <md-table-row slot="md-table-row">
            <md-table-cell class="label">ID</md-table-cell>
            <md-table-cell>{{ company?.code || "" }}</md-table-cell>
          </md-table-row>
          <md-table-row slot="md-table-row">
            <md-table-cell class="label">Name</md-table-cell>
            <md-table-cell>{{ company?.name || "" }}</md-table-cell>
          </md-table-row>
          <md-table-row slot="md-table-row">
            <md-table-cell class="label">Country</md-table-cell>
            <md-table-cell class="country">
              {{ company?.country == "pl" ? "Poland" : "Spain" }}
              <flag :iso="company?.country || 'es'" />
            </md-table-cell>
          </md-table-row>
        </md-table>
        <li class="adjustments-line text-center"></li>
        <li class="header-title">Account Information</li>
        <md-table class="md-table-plugin">
          <md-table-row slot="md-table-row">
            <md-table-cell class="label">First name</md-table-cell>
            <md-table-cell>{{ user?.first_name || "" }}</md-table-cell>
          </md-table-row>
          <md-table-row slot="md-table-row">
            <md-table-cell class="label">Last name</md-table-cell>
            <md-table-cell>{{ user?.last_name || "" }}</md-table-cell>
          </md-table-row>
          <md-table-row slot="md-table-row">
            <md-table-cell class="label">Email</md-table-cell>
            <md-table-cell>{{ user?.email || "" }}</md-table-cell>
          </md-table-row>
          <md-table-row slot="md-table-row">
            <md-table-cell class="label">Mobile</md-table-cell>
            <md-table-cell>{{ user?.phone || "" }}</md-table-cell>
          </md-table-row>
        </md-table>
        <li class="adjustments-line text-center"></li>
        <li class="button-container">
          <md-button class="md-block md-danger" @click.prevent="onResetPassword">Reset Password</md-button>
          <md-button class="md-block md-primary" @click.prevent="onLogout">Logout</md-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useAuthStore } from "@/store/auth"
import router from "@/router"

export default {
  setup() {
    const auth = useAuthStore()

    const user = computed(() => {
      return auth.user
    })

    const company = computed(() => {
      return auth.company
    })

    const onLogout = () => {
      auth.logout().then(() => {
        auth.$reset()
      })
    }

    const onResetPassword = () => {
      router.push({ path: "/forgot" })
    }

    return { user, company, onLogout, onResetPassword }
  },

  data() {
    return {
      isOpen: false,
    }
  },

  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen
    },

    closeDropDown() {
      this.isOpen = false
    },
  },
}
</script>

<style lang="scss">
.fixed-plugin .dropdown .dropdown-menu li {
  &.header-title {
    margin-top: 12px !important;
  }
}

.md-table-plugin {
  padding: 12px !important;

  table {
    border: none !important;

    .md-table-cell {
      height: 12px !important;
      padding: 6px !important;

      &.label {
        font-weight: 600 !important;
      }
    }

    .md-table-row td {
      border-top: 0px !important;
    }
  }
}

.adjustments-line {
  height: 6px !important;
  margin-bottom: 6px !important;
}
</style>
