import MdDialog from "./MdDialog"
import MdDialogTitle from "./MdDialogTitle"
import MdDialogContent from "./MdDialogContent"
import MdDialogActions from "./MdDialogActions"

const MdDialogPlugin = {
  install(app) {
    app.component(MdDialog.name, MdDialog)
    app.component(MdDialogTitle.name, MdDialogTitle)
    app.component(MdDialogContent.name, MdDialogContent)
    app.component(MdDialogActions.name, MdDialogActions)
  },
}

export default MdDialogPlugin
