import AuthLayout from "@/pages/Auth/Layout.vue"
import AdminLayout from "@/pages/Admin/Layout.vue"
import DcaLayout from "@/pages/DCA/Layout.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Auth",
    component: AuthLayout,
    redirect: "/login",
    children: [
      {
        path: "/login",
        name: "Login",
        meta: { guestOnly: true },
        component: () => import("@/pages/Auth/Login.vue"),
      },
      {
        path: "/lock",
        name: "Lock",
        meta: { authOnly: true },
        component: () => import("@/pages/Auth/Lock.vue"),
      },
      {
        path: "/forgot",
        name: "Forgot",
        component: () => import("@/pages/Auth/Forgot.vue"),
      },
      {
        path: "/set-password",
        name: "SetPassword",
        meta: { guestOnly: true },
        component: () => import("@/pages/Auth/SetPassword.vue"),
      },
      {
        path: "/register",
        name: "Register",
        redirect: "/dca/register",
      },
      {
        path: "/dca/register",
        name: "Register:DCA",
        meta: { guestOnly: true },
        component: () => import("@/pages/Auth/Register/DCA.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminLayout,
    meta: { navbarAbsolute: true },
    redirect: "/admin/login",
    children: [
      {
        path: "/admin/dashboard",
        name: "Admin:Dashboard",
        meta: { authOnly: true, adminOnly: true },
        component: () => import("@/pages/Admin/Dashboard.vue"),
      },
      {
        path: "/admin/companies",
        name: "Admin:Companies",
        meta: { authOnly: true, adminOnly: true },
        component: () => import("@/pages/Admin/Companies.vue"),
      },
      {
        path: "/admin/dca",
        name: "Admin:DCA",
        meta: { authOnly: true, adminOnly: true },
        component: () => import("@/pages/Admin/DCA.vue"),
      },
      {
        path: "/admin/internal",
        name: "Admin:Internal",
        meta: { authOnly: true, adminOnly: true },
        component: () => import("@/pages/Admin/Internal.vue"),
      },
      {
        path: "/admin/vendor",
        name: "Admin:Vendor",
        meta: { authOnly: true, adminOnly: true },
        component: () => import("@/pages/Admin/Vendor.vue"),
      },
    ],
  },
  {
    path: "/admin/login",
    component: AuthLayout,
    children: [
      {
        path: "/admin/login",
        name: "Admin Login",
        meta: { guestOnly: true },
        component: () => import("@/pages/Admin/Login.vue"),
      },
    ],
  },
  {
    path: "/dca",
    name: "DCA",
    component: DcaLayout,
    redirect: "/dca/dashboard",
    children: [
      {
        path: "/dca/dashboard",
        name: "DCA Dashboard",
        meta: { authOnly: true, dcaOnly: true },
        component: () => import("@/pages/DCA/Dashboard.vue"),
      },
    ],
  },
]

export default routes
