<template>
  <div>
    <infinite-scroll
      v-if="!isEmpty"
      @infinite:scroll="onLoadMore"
      :is-last-page="false"
      className="timeline timeline-wrapper timeline-simple"
    >
      <timeline-item
        v-for="(item, index) in visible"
        inverted
        badge-type="primary"
        badge-icon="contact_page"
        :key="index"
      >
        <template #header>
          <slot name="header" :item="item"></slot>
        </template>
        <template #content>
          <slot name="body" :item="item"></slot>
        </template>
      </timeline-item>
    </infinite-scroll>
    <st-empty text="No transactions made last month" v-else></st-empty>
  </div>
</template>

<script>
import TimelineItem from "./TimelineItem.vue"
import { ref, computed, onMounted, effect } from "vue"

export default {
  name: "timeline-content",

  components: {
    TimelineItem,
  },

  props: {
    isFetching: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    items: [Array, Object],
    total: Number,
    perPage: {
      type: Number,
      default: 6,
    },
  },

  setup(props) {
    const perPage = ref(props.perPage)
    const data = ref(props.items)
    const total = ref(props.total || 1)
    const visible = ref([])
    const current = ref(0)

    const isShowingMore = ref(false)

    const isLast = computed(() => {
      return props.total >= current.value
    })

    const onLoadMore = () => {
      const start = current.value
      const end = current.value + perPage.value

      if (start < total.value) {
        const sliced = data.value.slice(start, end)
        visible.value = [...visible.value, ...sliced]
        current.value = end
      }

      return (isShowingMore.value = true)
    }

    onMounted(() => {
      visible.value = props.items
      data.value = props.items

      let interval = null

      interval = setInterval(() => {
        if (!props.isFetching) {
          data.value = props.items
          visible.value = props.items.slice(0, props.perPage)
          total.value = props.total
          clearInterval(interval)
        }
      }, 500)
    })

    return {
      visible,
      isLast,
      isShowingMore,
      onLoadMore,
    }
  },
}
</script>

<style lang="scss">
.load-more-items {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555454;
  font-weight: 600;

  .loader__dot {
    animation: 1s blink infinite;
  }
  .loader__dot:nth-child(2) {
    animation-delay: 250ms;
  }
  .loader__dot:nth-child(3) {
    animation-delay: 500ms;
  }
}

@keyframes blink {
  50% {
    color: transparent;
  }
}

.md-table-content {
  width: 100%;

  @media (max-width: 576px) {
    width: 280px;
  }
}

.timeline {
  &:before {
    left: 5%;
    background-color: rgba(53, 7, 64) !important;
  }

  > li {
    margin-bottom: 0px !important;
  }

  > li > .timeline-badge {
    left: 5%;
  }

  > li.timeline-inverted > .timeline-panel {
    position: relative;
    float: unset !important;
    margin-left: 92px !important;
    display: flex;
    gap: 10px;

    @media (max-width: 589px) {
      margin-left: 80px !important;
    }

    @media (max-width: 497px) {
      display: flex;
      flex-direction: column;
      margin-left: 70px !important;
    }
  }
}

.timeline-wrapper.timeline:before {
  display: none;
}

.timeline-wrapper {
  position: relative;
  z-index: 1000;
  max-height: 500px;
  min-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;

  .timeline-panel {
    .timeline-heading {
      margin-right: 50px;
    }
  }

  .timeline-panel div:nth-child(2) {
    width: 100%;
  }
}

.timeline-content {
  min-height: 514px;

  > div {
    width: 100%;
  }
}
</style>
