const useDownload = () => {
  const download = (data, type, filename) => {
    let blob = new Blob([data], { type: type })
    let link = document.createElement("a")
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
  }

  const stream = (filepath, filename) => {
    let link = document.createElement("a")
    link.href = filepath
    link.download = filename
    link.click()

    return true
  }

  return { download, stream }
}

export default useDownload
