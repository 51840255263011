<template>
  <div class="chart-container" :style="{ height: height + 'px' }">
    <md-progress-spinner :md-diameter="35" :md-stroke="4" md-mode="indeterminate" v-if="isLoading" />
    <line-component
      :id="chartId"
      :ref="refName"
      :style="chartStyles"
      :options="chartOptions"
      :data="chartData"
      v-else
    />
  </div>
</template>

<script>
import { Line as LineComponent } from "vue-chartjs"

export default {
  name: "line-chart",

  components: { LineComponent },

  props: {
    refName: {
      type: String,
      default: "line-chart",
    },
    chartData: Object,
    chartOptions: Object,
    height: Number,
    isLoading: Boolean,
    displayLegend: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    chartId() {
      return "line-chart-" + Math.random()
    },

    chartStyles() {
      return {
        height: `${this.height}px`,
        width: "100%",
      }
    },
  },
}
</script>
