<template>
  <div class="extra-menu">
    <!-- <sidebar-item
      :link="{ name: 'Help Center', icon: 'help_center', path: '/admin/' }"
      @click="e => $emit('change:route', e)"
    />
    <sidebar-item
      :link="{ name: 'Audits', icon: 'manage_history', path: '/admin/' }"
      @click="e => $emit('change:route', e)"
    /> -->
  </div>
</template>

<script>
export default {
  name: "extra-menu",
  emits: ["change:route"],
}
</script>
