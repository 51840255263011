<template>
  <input
    v-bind="attributes"
    class="md-input"
    @focus="onFocus"
    @blur="onBlur"
    @keydown.space="onKeydownSpace"
    @keydown.enter="onSubmit"
  />
</template>

<script>
import MdComponent from "../../core/MdComponent"
import MdUuid from "../../core/utils/MdUuid"
import MdFieldMixin from "../MdFieldMixin"

export default new MdComponent({
  name: "MdInput",
  mixins: [MdFieldMixin],
  inject: ["MdField"],
  props: {
    id: {
      type: String,
      default: () => "md-input-" + MdUuid(),
    },
    type: {
      type: String,
      default: "text",
    },
    preventSpace: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    bindProps: Object,
  },
  computed: {
    toggleType() {
      return this.MdField.togglePassword
    },
    isPassword() {
      return this.type === "password"
    },
  },
  watch: {
    type() {
      this.setPassword(this.isPassword)
    },
    toggleType(toggle) {
      if (toggle) {
        this.setTypeText()
      } else {
        this.setTypePassword()
      }
    },
  },
  methods: {
    setPassword(state) {
      this.MdField.password = state
      this.MdField.togglePassword = false
    },
    setTypePassword() {
      this.$el.type = "password"
    },
    setTypeText() {
      this.$el.type = "text"
    },
    onKeydownSpace(event) {
      if (this.preventSpace) {
        event.preventDefault()
      }
    },
  },
  created() {
    this.setPassword(this.isPassword)
  },
  mounted() {
    if (this.isPassword) {
      this.setTypePassword()
    }
  },
  beforeUnmount() {
    this.setPassword(false)
  },
})
</script>
