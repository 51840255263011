<template>
  <md-card
    class="md-card-tabs"
    :class="[{ 'flex-column': flexColumn }, { 'nav-pills-icons': navPillsIcons }, { 'md-card-plain': plain }]"
  >
    <md-card-content>
      <md-list class="nav-tabs">
        <md-list-item
          v-for="(item, index) in tabs"
          :value="modelValue"
          @click="switchPanel(tabs[index], index)"
          :key="item.name"
          :class="[
            { active: isActivePanel(tabs[index]) },
            { [getColorButton(tabs[index].color)]: isActivePanel(tabs[index]) },
          ]"
        >
          {{ tabs[index].name }}
          <md-icon v-if="navPillsIcons">{{ tabs[index].icon }}</md-icon>
        </md-list-item>
      </md-list>

      <fade-transition :duration="200">
        <div class="tab-content">
          <template v-for="(item, index) in tabs">
            <template v-if="isActivePanel(tabs[index])">
              <div :class="getTabName(item.key)" :key="item.name">
                <slot :name="getTabName(item.key)" :index="index"></slot>
              </div>
            </template>
          </template>
        </div>
      </fade-transition>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "st-tabs",

  props: {
    flexColumn: Boolean,
    navPillsIcons: Boolean,
    plain: Boolean,
    tabs: Array,
    modelValue: String,
  },

  data() {
    return {
      activePanel: this.tabs[0].name,
    }
  },

  methods: {
    switchPanel(panel, index) {
      this.activePanel = panel.name
      this.$emit("update:modelValue", panel.name)
    },
    isActivePanel(panel) {
      return this.activePanel == panel.name
    },
    getColorButton: function (colorButton) {
      return "md-" + colorButton
    },
    getTabName: function (key) {
      return "tab-panel-" + key
    },
    getTabContent: function (index) {
      return "tab-panel-" + index
    },
  },

  emits: ["update:modelValue"],
}
</script>

<style lang="scss">
.user-types-tabs {
  .md-list-item:not(.active) {
    .md-list-item-content {
      color: #fff !important;
      opacity: 0.6;

      i {
        color: #fff !important;
        opacity: 0.6;
      }

      &:hover {
        opacity: 1 !important;

        i {
          opacity: 1 !important;
        }
      }
    }
  }
}
</style>
