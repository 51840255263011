//import MdTheme from './MdTheme'
import { defineComponent } from "vue"
import deepmerge from "deepmerge"

export default function (newComponent) {
  const defaults = defineComponent({
    props: {
      mdTheme: "md-theme-default",
    },
    computed: {
      $mdActiveTheme() {
        return "md-theme-default"
      },
    },
  })

  return deepmerge(defaults, newComponent)
}
