import { LineChart } from "@/components"
import { BarChart } from "@/components"
import { DoughnutChart } from "@/components"

import { Chart as ChartJS, registerables } from "chart.js"
ChartJS.register(...registerables)

const ChartPlugin = {
  install(app) {
    app.component("line-chart", LineChart)
    app.component("bar-chart", BarChart)
    app.component("doughnut-chart", DoughnutChart)
  },
}

export default ChartPlugin
