<template>
  <md-table class="md-table-timeline">
    <md-table-row class="md-table-head">
      <md-table-cell></md-table-cell>
      <md-table-cell>Requests</md-table-cell>
      <md-table-cell>Hits</md-table-cell>
      <md-table-cell>Hit Rate</md-table-cell>
    </md-table-row>
    <md-table-row slot="md-table-row" v-for="(item, index) in items" :key="index">
      <md-table-cell>{{ item.name }}</md-table-cell>
      <md-table-cell>{{ item.requests }}</md-table-cell>
      <md-table-cell>{{ item.hits }}</md-table-cell>
      <md-table-cell>{{ item.hitRate }}</md-table-cell>
    </md-table-row>
  </md-table>
</template>

<script>
export default {
  name: "timeline-table",

  props: {
    items: Array,
  },
}
</script>

<style lang="scss">
.md-table-timeline {
  padding: 12px !important;

  .md-table-cell {
    height: 24px !important;
    padding: 6px !important;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}
</style>
