import material from "@/components/Material/material"
import * as MdComponents from "@/components/Material"
import MdTheme from "@/components/Material/core/MdTheme"
import "@/components/Material/css/vue-material.min.css"

const VueMaterial = {
  install(app) {
    app.config.globalProperties.$material = material
    app.config.globalProperties.$material.theming = {
      metaColors: MdTheme.metaColors,
      theme: MdTheme.theme,
      enabled: MdTheme.enabled,
    }

    Object.values(MdComponents).forEach(MdComponent => {
      app.use(MdComponent)
    })
  },
}

export default VueMaterial
