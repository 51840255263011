<template>
  <div class="md-speed-dial-content">
    <slot />
  </div>
</template>

<script>
export default {
  name: "MdSpeedDialContent",
  inject: ["MdSpeedDial"],
}
</script>

<style lang="scss">
@import "../MdAnimation/variables";

.md-speed-dial-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  transition: 0.3s $md-transition-default-timing;
}
</style>
