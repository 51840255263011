<template>
  <md-card class="md-card-chart">
    <md-card-header
      :class="[
        { [getClass(backgroundColor)]: true },
        { 'md-card-header-text': HeaderText },
        { 'md-card-header-icon': HeaderIcon },
      ]"
    >
      <slot name="chart-inside-header"></slot>
    </md-card-header>

    <md-card-content>
      <slot name="chart-inside-content"></slot>
      <div class="chart-content">
        <div class="chart-content-details">
          <h4 class="title">{{ chartTitle }}</h4>
          <div class="category chart-label">
            <div class="chart-item-label" v-for="(content, index) in chartContent" :key="index">
              <span class="legend" :class="content.color"></span>
              <span class="label">{{ content.label }}</span>
            </div>
          </div>
        </div>
        <segmented-button
          class-name="chart-frequency"
          :items="['Daily', 'Weekly', 'Monthly']"
          :value="frequency"
          @select:item="value => onUpdateData(value)"
        />
      </div>
    </md-card-content>

    <md-card-actions md-alignment="left" v-if="!noFooter">
      <div class="stats" :key="'stats-' + isLoading">
        <md-icon>access_time</md-icon>
        For the last
        <animated-number :value="chartRange"></animated-number>
        {{ frequencyLabel }}
      </div>
    </md-card-actions>
  </md-card>
</template>

<script>
import { SegmentedButton } from "@/components"

export default {
  name: "chart-card",

  components: {
    SegmentedButton,
  },

  props: {
    HeaderText: Boolean,
    HeaderIcon: Boolean,
    noFooter: Boolean,
    chartInsideContent: Boolean,
    backgroundColor: String,
    isLoading: Boolean,
    chartTitle: String,
    chartContent: Array,
    chartRange: Number,
    chartMaxValue: Number,
  },

  data() {
    return {
      frequency: "Daily",
    }
  },

  computed: {
    frequencyLabel() {
      if (this.frequency == "Monthly") {
        return "Months"
      }

      if (this.frequency == "Weekly") {
        return "Weeks"
      }

      return "Days"
    },
  },

  methods: {
    getClass: function (backgroundColor) {
      return "md-card-header-" + backgroundColor + ""
    },

    onUpdateData(value) {
      this.frequency = value
      this.$emit("update:modelValue", value)
    },
  },

  emits: ["update:modelValue"],
}
</script>

<style lang="scss">
.chart-content {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }

  .chart-content-details {
    display: flex;
    flex-direction: column;
  }

  .chart-frequency {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;

    @media (max-width: 768px) {
      justify-content: flex-start;
      width: 100%;
    }
  }
}

.chart-container {
  position: relative;
  width: 100% !important;

  display: flex;
  align-items: center;
  justify-content: center;
}

#chart-legend {
  display: flex !important;
  flex-direction: column;
  align-items: flex-end;

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 12px;
  }

  li {
    list-style-type: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 18px;
  }

  li span:not(.label) {
    width: 28px;
    height: 6px;
    margin-right: 6px;

    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
