import api from "@/api/axios"

export default {
  async statistics() {
    return await api.get("/v1/statistics")
  },

  async chartData({ frequency }) {
    return await api.get("/v1/chart", {
      params: { frequency: frequency },
    })
  },
}
