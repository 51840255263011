<template>
  <md-dialog v-bind="$attrs" :md-fullscreen="false">
    <md-dialog-title v-if="mdTitle">{{ mdTitle }}</md-dialog-title>
    <md-dialog-content v-if="mdContent" v-html="mdContent" />

    <md-dialog-actions>
      <md-button class="md-primary" @click="onConfirm()">{{ mdConfirmText }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import MdButton from "@/components/Material/MdButton/MdButton.vue"
import MdDialog from "@/components/Material/MdDialog/MdDialog.vue"
import MdDialogContent from "@/components/Material/MdDialog/MdDialogContent.vue"
import MdDialogActions from "@/components/Material/MdDialog/MdDialogActions.vue"
import MdDialogTitle from "@/components/Material/MdDialog/MdDialogTitle.vue"

export default {
  emits: ["update:mdActive"],
  components: {
    MdButton,
    MdDialogActions,
    MdDialogTitle,
    MdDialog,
    MdDialogContent,
  },
  name: "MdDialogAlert",
  props: {
    mdTitle: String,
    mdContent: String,
    mdConfirmText: {
      type: String,
      default: "Ok",
    },
  },
  methods: {
    onConfirm() {
      this.$emit("md-confirm")
      this.$emit("update:mdActive", false)
    },
  },
}
</script>
