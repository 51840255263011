import auth from "@/api/auth"
import router from "@/router"
import { defineStore } from "pinia"
import useAlert from "@/hooks/useAlert"

const { loading, danger, success, toast, close } = useAlert()

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      user: null,
      token: null,
      company: null,
      loggedIn: false,
      loggedInAs: null,
      locked: false,
      loading: false,
      error: null,
      isLoggingOut: false,
    }
  },

  actions: {
    async checkCompany(companyId) {
      this.loading = true
      return await auth
        .checkCompany(companyId)
        .then(response => {
          this.loading = false
          return response.data.data
        })
        .catch(error => {
          this.loading = false
          let message = error.response?.data?.message || error.message
          return message
        })
    },

    async register(payload) {
      this.loading = true
      loading.fire({
        title: "Please wait while we are processing your request!",
      })

      return await auth
        .register(payload)
        .then(response => {
          this.loading = false
          close()

          return response.data.data
        })
        .catch(error => {
          this.loading = false
          let message = error.response?.data?.message || error.message

          close()
          danger.fire({
            text: message,
          })

          return (this.error = message)
        })
    },

    async login(payload) {
      this.loading = true
      loading.fire({
        title: "Logging in...",
      })

      return await auth
        .login(payload)
        .then(response => {
          this.loggedIn = true
          this.loggedInAs = payload.login_as
          this.token = response.data.data.token
          this.user = response.data.data.user
          this.company = response.data.data.company
          close()

          setTimeout(() => {
            this.loading = false
            const loggedInAs = payload.login_as.toLowerCase()
            router.push({
              path: `/${loggedInAs}/dashboard`,
            })
          }, 1000)

          return true
        })
        .catch(error => {
          router.push({ path: "/login" })
          this.loading = false
          this.user = null
          this.company = null
          close()

          let message = error.response?.data?.message || error.message
          this.error = message

          if (error.response?.status == 401) {
            danger.fire({
              title: "Wrong Credentials",
              text: "Invalid Company ID, Email or Password",
            })

            return false
          }

          if (error.response?.status == 403) {
            danger.fire({
              title: "Unauthorized",
              text: message,
            })

            return false
          }

          danger.fire({
            text: message,
          })

          return false
        })
    },

    async logout() {
      if (!this.user && !this.loggedIn && this.isLoggingOut) {
        return false
      }

      this.loading = true
      this.isLoggingOut = true

      return await auth
        .logout()
        .then(response => {
          this.loggedIn = false
          this.loggedInAs = null
          this.user = null
          this.company = null

          setTimeout(() => {
            this.loading = false
            if (router.currentRoute.value.name.includes("Admin")) {
              router.push({ path: "/admin/login" })
              return response
            }

            router.push({ path: "/login" })
            return response
          }, 250)

          return true
        })
        .catch(error => {
          this.loading = false
          let message = error.response?.data?.message || error.message

          close()
          danger.fire({
            text: message,
          })

          return (this.error = message)
        })
    },

    lockAccount() {
      this.locked = true
      router.push({ path: "/lock" })
    },

    async unlockAccount(payload) {
      this.loading = true
      loading.fire({
        title: "Verifying account...",
      })

      return await auth
        .unlock(payload)
        .then(response => {
          this.locked = false
          this.user = response.data.data.user
          this.company = response.data.data.company

          close()
          const loggedInAs = this.user.type.toLowerCase()
          router.push({
            path: `/${loggedInAs}/dashboard`,
          })
        })
        .catch(error => {
          this.loading = false
          let message = error.response?.data?.message || error.message

          close()
          danger.fire({
            text: message,
          })

          return (this.error = message)
        })
    },

    async forgotPassword(payload) {
      this.loading = true
      loading.fire({
        title: "Please wait while we are processing your request!",
      })

      return await auth
        .forgotPassword(payload)
        .then(response => {
          this.loading = false

          close()
          success.fire({
            text: response.data.message,
          })

          setTimeout(() => {
            router.push({ path: "/login" })
          }, [500])
          return response.data.data
        })
        .catch(error => {
          this.loading = false
          let message = error.response?.data?.message || error.message

          close()
          if (message === "passwords.user") {
            danger.fire({
              title: "Failed",
              text: "Email doesn't exist",
            })

            return (this.error = message)
          }

          if (message === "passwords.throttled") {
            danger.fire({
              title: "Failed",
              text: "Please try again later",
            })
            return (this.error = message)
          }

          danger.fire({
            text: message,
          })

          return (this.error = message)
        })
    },

    async resetPassword({ id, params }) {
      this.loading = true
      loading.fire({
        title: "Validating reset password link...",
      })

      return await auth
        .resetPassword(id, params)
        .then(response => {
          close()
          return response.data.data
        })
        .catch(error => {
          let message = error.response?.data?.message || error.message

          close()
          danger
            .fire({
              text: message,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            .then(result => {
              if (result.isConfirmed) {
                router.push({ path: "/login" })
              }
            })

          return (this.error = message)
        })
    },

    async setPassword(payload) {
      this.loading = true
      loading.fire({
        title: "Please wait while we are processing your request!",
      })

      return await auth
        .setPassword(payload)
        .then(response => {
          this.loading = false

          close()
          toast.fire({
            icon: "success",
            title: "Password reset successfully",
          })

          setTimeout(() => {
            router.push({ path: "/login" })
          }, [500])

          return response.data.data
        })
        .catch(error => {
          this.loading = false
          let message = error.response?.data?.message || error.message

          close()
          if (message == "passwords.token") {
            danger.fire({
              title: "Failed",
              text: "This link has already been used. Please reset password again to send a new one!",
            })

            return (this.error = message)
          }

          danger.fire({
            text: message,
          })

          return (this.error = message)
        })
    },
  },

  persist: true,
})
