import MdMenu from "./MdMenu"
import MdMenuContent from "./MdMenuContent"
import MdMenuItem from "./MdMenuItem"

const MdMenuPlugin = {
  install(app) {
    app.component(MdMenu.name, MdMenu)
    app.component(MdMenuContent.name, MdMenuContent)
    app.component(MdMenuItem.name, MdMenuItem)
  },
}

export default MdMenuPlugin
